import { type Ref } from "vue";

import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";
import {
  Alignment,
  ConfigTabItem,
  ResizingType,
  State,
  VerticalPosition,
} from "~~/models/widgets/widget-controls.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { typography } from "~~/constants/configs/text-common/typography-config";

import { useWidgetFields } from "../useWidgetFields";

import { useFlexibleImageControls } from "./useFlexibleCardWidgetControls";

export function usePromoGroupControls(
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>
) {
  const widgetSettingsStore = useWidgetSettingsStore();

  const fields = useWidgetFields(widget.value as IWidgetWithFields);
  const { selectedField } = storeToRefs(widgetSettingsStore);

  const promoGroupListDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const promoGroupListContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigDisplayGridSettings",
            valuePath: "options.gridSettings",
            valueSource: selectedField.value,
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Promo Cards",
              value: "promo_cards",
            },
          ],
        },
      },
    ];
  });

  const promoCardsDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigFillImageColor",
        valuePath: "options.fillImageColor",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
        },
      },
    ];
  });

  const promoCardsContentConfigItems: ConfigTabItem[] = [
    {
      label: "Image",
      value: "promo_image",
      isActiveValueSource: fields.value.promo_image.field.options,
    },
    {
      label: "Title",
      value: "promo_title",
      isActiveValueSource: fields.value.promo_title.field.options,
    },
    {
      label: "Description",
      value: "promo_description",
      isActiveValueSource: fields.value.promo_description?.field.options,
    },
    {
      label: "Primary Description",
      value: "promo_primary_description",
      isActiveValueSource:
        fields.value.promo_primary_description?.field.options,
    },
    {
      label: "Secondary Description",
      value: "promo_secondary_description",
      isActiveValueSource:
        fields.value.promo_secondary_description?.field.options,
    },
    {
      label: "Button group",
      value: "promo_button_group",
      isActiveValueSource: fields.value.promo_button_group.field.options,
    },
  ].filter(item => item.isActiveValueSource);

  const ALIGN_VALUES = [
    {
      title: "Top Left",
      value: `${VerticalPosition.TOP} ${Alignment.LEFT}`,
      icon: "ant-design:picture-filled",
    },
    {
      title: "Top Center",
      value: `${VerticalPosition.TOP} ${Alignment.CENTER}`,
      icon: "ant-design:picture-filled",
    },
    {
      title: "Top Right",
      value: `${VerticalPosition.TOP} ${Alignment.RIGHT}`,
      icon: "ant-design:picture-filled",
    },
    {
      title: "Center Left",
      value: `${VerticalPosition.CENTER} ${Alignment.LEFT}`,
      icon: "ant-design:picture-filled",
    },
    {
      title: "Center Center",
      value: `${VerticalPosition.CENTER} ${Alignment.CENTER}`,
      icon: "ant-design:picture-filled",
    },
    {
      title: "Center Right",
      value: `${VerticalPosition.CENTER} ${Alignment.RIGHT}`,
      icon: "ant-design:picture-filled",
    },
    {
      title: "Bottom Left",
      value: `${VerticalPosition.BOTTOM} ${Alignment.LEFT}`,
      icon: "ant-design:picture-filled",
    },
    {
      title: "Bottom Center",
      value: `${VerticalPosition.BOTTOM} ${Alignment.CENTER}`,
      icon: "ant-design:picture-filled",
    },
    {
      title: "Bottom Right",
      value: `${VerticalPosition.BOTTOM} ${Alignment.RIGHT}`,
      icon: "ant-design:picture-filled",
    },
  ];

  const promoCardsContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Size"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.containerSize.height.type",
            valueSource: selectedField.value,
            options: {
              label: "Height",
              items: [
                {
                  label: "Fixed",
                  value: ResizingType.FIXED,
                },
                {
                  label: "Fill",
                  value: ResizingType.FILL,
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.containerSize.height.value",
            className: "p-l-16 p-r-16 p-t-16",
            valueSource: selectedField.value,
            visible:
              selectedField.value?.options.containerSize.height.type ===
              ResizingType.FIXED,
            options: {
              minValue: 1,
              maxValue: 1024,
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Display Mode"),
        controls: [
          {
            componentPath: "ConfigLayoutPosition",
            valuePath: "options.layoutPosition",
            valueSource: selectedField.value,
            options: {
              showAlignment: false,
            },
          },
          {
            componentPath: "ConfigAlignmentFull",
            valuePath: "options.layoutPosition.alignment",
            valueSource: selectedField.value,
            className: "group-control-element--child",
            options: {
              label: "Alignment",
              customAlignValues: ALIGN_VALUES,
              customIconName: "ant-design:picture-filled",
            },
          },
          {
            componentPath: "content/ConfigNumberInput",
            valuePath: "options.gap",
            valueSource: selectedField.value,
            options: {
              label: "Distance between elements",
              suffixContent: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: promoCardsContentConfigItems,
        },
      },
    ];
  });

  const promoCardsStateValuePath = computed<string>(() => {
    if (states.value[selectedField.value!.name] === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value[selectedField.value!.name]}`;
  });

  const promoCardsStatesControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value[selectedField.value!.name],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
          ],
        },
        onUpdate(value: State) {
          states.value.promo_cards = value;
        },
      },
      {
        componentPath: "ConfigFillImageColor",
        valuePath: `${promoCardsStateValuePath.value}.fillImageColor`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${promoCardsStateValuePath.value}.border`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${promoCardsStateValuePath.value}.shadow`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
    ];
  });

  const { flexibleImageControls: promoImageControls } =
    useFlexibleImageControls(toRef(fields.value.promo_image.field));

  const promoTitleControls = computed(() => [
    typography({ source: selectedField.value, except: ["ConfigAlignment"] }),
    {
      componentPath: "ConfigPaddingInputs",
      valuePath: "options.padding",
      valueSource: selectedField.value,
      options: {
        label: "Padding",
        meta: {
          isBold: true,
        },
      },
    },
  ]);

  const promoButtonGroupDesignControls = computed(() => [
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: "options.fill",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Fill",
        isBold: true,
        type: ColorPickerType.BACKGROUND,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: "options.border",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Border",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: "options.shadow",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Shadow",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigCornerRadius",
      valuePath: "options.cornerRadius",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Corner radius",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigSpacingInputs",
      valuePath: "options.spacing",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Spacing",
      },
    },
  ]);

  const promoButtonGroupContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.layout",
            valueSource: selectedField.value,
            options: {
              label: "Layout",
              items: [
                {
                  label: "Vertical",
                  value: "vertical",
                },
                {
                  label: "Horizontal",
                  value: "horizontal",
                },
              ],
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.gap",
            valueSource: selectedField.value,
            className: "group-control-element",
            options: {
              label: "Distance between buttons",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
          {
            componentPath: "ConfigAlignment",
            valuePath: "options.alignment",
            valueSource: selectedField.value,
            options: {
              label: "Alignment",
              isTextContentIcons: false,
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Button",
              value: "promo_button_1",
              isActiveValueSource: fields.value.promo_button_1.field.options,
            },
            {
              label: "Button",
              value: "promo_button_2",
              isActiveValueSource: fields.value.promo_button_2.field.options,
            },
          ],
        },
      },
    ];
  });

  const promoButtonDesignControls = computed(() => [
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: "options.fill",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Fill",
        isBold: true,
        type: ColorPickerType.BACKGROUND,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: "options.border",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Border",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: "options.shadow",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Shadow",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigCornerRadius",
      valuePath: "options.cornerRadius",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Corner radius",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigSpacingInputs",
      valuePath: "options.spacing",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Spacing",
      },
    },
  ]);

  const promoButtonContentControls = computed(() => [
    {
      section: generateDesignSectionName("Text"),
      controls: [
        {
          componentPath: "ConfigButtonTextSettings",
          valuePath: "options.buttonTextSettings",
          className: "p-t-16",
          valueSource: selectedField.value,
          options: {
            label: "Text",
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Icon"),
      controls: [
        {
          componentPath: "ConfigIconSettings",
          valuePath: "options.iconSettings",
          valueSource: selectedField.value,
          options: {
            isStatesTab: false,
          },
        },
      ],
    },
    {
      componentPath: "ConfigButtonDisplaySettings",
      valuePath: "options.buttonDisplaySettings",
      valueSource: selectedField.value,
      options: {
        showAlignmentOnFillOnly: false,
      },
    },
  ]);

  const promoButtonSettingsValuePath = computed<string>(() => {
    if (states.value[selectedField.value!.name] === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value[selectedField.value!.name]}`;
  });

  const promoButtonStatesControls = computed(() => [
    {
      componentPath: "common/ConfigStatesList",
      valuePath: "",
      valueSource: states.value[selectedField.value!.name],
      options: {
        items: [
          {
            label: STATE_KEY_LABELS.default,
            value: State.DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.hover,
            value: State.HOVER,
          },
          {
            label: STATE_KEY_LABELS.active,
            value: State.ACTIVE,
          },
          {
            label: STATE_KEY_LABELS.disabled,
            value: State.DISABLED,
          },
        ],
      },
      onUpdate(value: State) {
        states.value[selectedField.value!.name] = value;
      },
    },
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: `${promoButtonSettingsValuePath.value}.fill`,
      valueSource: selectedField.value,
      options: {
        label: "Fill",
        type: ColorPickerType.BACKGROUND,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: `${promoButtonSettingsValuePath.value}.border`,
      valueSource: selectedField.value,
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: `${promoButtonSettingsValuePath.value}.shadow`,
      valueSource: selectedField.value,
    },
    {
      section: generateDesignSectionName("Text"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${promoButtonSettingsValuePath.value}.buttonTextSettings.textColor`,
          valueSource: selectedField.value,
          options: {
            label: "Text color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Icon"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${promoButtonSettingsValuePath.value}.iconSettings.textColor`,
          valueSource: selectedField.value,
          options: {
            label: "Icon color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
  ]);

  return {
    promoGroupListDesignControls,
    promoGroupListContentControls,

    promoCardsDesignControls,
    promoCardsContentControls,
    promoCardsStatesControls,

    promoImageControls,

    promoTitleControls,

    promoButtonGroupDesignControls,
    promoButtonGroupContentControls,
    promoButtonDesignControls,
    promoButtonContentControls,
    promoButtonStatesControls,
  };
}
