import { type Ref } from "vue";

import {
  IWidgetWithFields,
  ColorPickerType,
} from "~~/models/widgets/widget.core/widget.model";
import {
  DisplayOrientation,
  State,
} from "~~/models/widgets/widget-controls.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import {
  generateContentSectionName,
  generateDesignSectionName,
} from "~~/helpers/configs/generate-section-name";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";
import { useFormFieldConfig } from "~~/composables/widgets/form/useFormFieldConfig";
import { useBetItemsConfig } from "~~/composables/widgets/sportsbook/useBetItemsConfig";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";

export const useBetsConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, string>>,
  emit: any
) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  const marketsContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.layout",
            valueSource: fields.value.markets.field,
            className: "group-control-element",
            options: {
              label: "Layout",
              noTextWrap: true,
              items: [
                {
                  label: "Vertical",
                  value: DisplayOrientation.VERTICAL,
                },
                {
                  label: "Horizontal",
                  value: DisplayOrientation.HORIZONTAL,
                },
              ],
            },
          },
          {
            componentPath: "ConfigHorizontalSpace",
            valuePath: "options.display.gap",
            valueSource: fields.value.markets.field,
            className: "group-control-element",
            options: {
              label: "Gap",
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              value: "outcomes",
              label: "Outcomes",
            },
          ],
        },
      },
    ];
  });

  const {
    betItemsDesignControls,
    betItemsContentControls,
    betItemsStateControls,
  } = useBetItemsConfig(states, emit);

  const betsContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.layout",
            valueSource: fields.value.bets.field,
            className: "group-control-element",
            options: {
              isBold: true,
              label: "Display mode",
              noTextWrap: true,
              items: [
                {
                  label: "Vertical",
                  value: DisplayOrientation.VERTICAL,
                },
                {
                  label: "Horizontal",
                  value: DisplayOrientation.HORIZONTAL,
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.direction",
            valueSource: fields.value.bets.field,
            className: "group-control-element",
            options: {
              label: "Direction",
              noTextWrap: true,
              items: [
                {
                  label: "Default",
                  value: "default",
                },
                {
                  label: "Reverse",
                  value: "reverse",
                },
              ],
            },
          },
          // {
          //   componentPath: "ConfigAlignment",
          //   valuePath: "options.alignment",
          //   valueSource: fields.value.bets.field,
          //   className: "border-bottom-none",
          //   options: {
          //     isTextContentIcons: false,
          //   },
          // },
          {
            componentPath: "ConfigHorizontalSpace",
            valuePath: "options.gap",
            valueSource: fields.value.bets.field,
            className: "group-control-element",
            options: {
              label: "Gap",
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              value: "input",
              label: "Input",
            },
            {
              value: "bet_amounts",
              label: "Bet amounts",
            },
          ],
        },
      },
    ];
  });

  const betsAmountContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigHorizontalSpace",
            valuePath: "options.display.gap",
            valueSource: fields.value.bet_amounts.field,
            className: "group-control-element",
            options: {
              label: "Gap",
              suffix: "px",
            },
          },
        ],
      },
      typography({
        source: fields.value.bet_amounts.field,
        except: ["ConfigAlignment"],
      }),
    ];
  });

  const betAmountsStatePath = computed<string>(() => {
    if (states.value.bet_amounts === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value.bet_amounts}`;
  });

  const betAmountsStatesControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value.bet_amounts,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
            {
              label: STATE_KEY_LABELS.disabled,
              value: State.DISABLED,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: "bet_amounts", value });
        },
      },
      ...getInitialDesignList({
        source: fields.value.bet_amounts.field,
        except: ["ConfigCornerRadius", "ConfigSpacingInputs"],
        basePath: betAmountsStatePath.value,
      }),
      {
        section: "Typography",
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${betAmountsStatePath.value}.color`,
            valueSource: fields.value.bet_amounts.field,
            options: {
              placeholder: "None",
              label: "Color",
              isBold: false,
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  const { formFieldDesignStyles, formFieldTypograpyControls, formFieldStates } =
    useFormFieldConfig(widget, states as Ref<Record<string, State>>, {
      fields_styling: "input",
    });

  const formFieldContentStyles = computed(() => {
    return [formFieldTypograpyControls.value];
  });

  const notEnoughFundsContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.layout",
            valueSource: fields.value.not_enough_container.field,
            className: "group-control-element",
            options: {
              isBold: true,
              label: "Layout",
              noTextWrap: true,
              items: [
                {
                  label: "Vertical",
                  value: DisplayOrientation.VERTICAL,
                },
                {
                  label: "Horizontal",
                  value: DisplayOrientation.HORIZONTAL,
                },
              ],
            },
          },
          {
            componentPath: "ConfigAlignment",
            valuePath: "options.display.alignment",
            valueSource: fields.value.not_enough_container.field,
            className: "border-bottom-none",
            visible:
              fields.value.not_enough_container.field.options.display.layout ===
              DisplayOrientation.VERTICAL,
            options: {
              isTextContentIcons: false,
            },
          },
          {
            componentPath: "ConfigHorizontalSpace",
            valuePath: "options.display.gap",
            valueSource: fields.value.not_enough_container.field,
            className: "group-control-element",
            options: {
              label: "Gap",
              suffix: "px",
            },
          },
        ],
      },
      typography(
        {
          source: fields.value.not_enough_container.field,
          except: ["ConfigAlignment"],
        },
        "Text"
      ),
      {
        section: generateDesignSectionName("Icon"),
        valueSource: fields.value.not_enough_container.field.options.icon,
        toggleable: true,
        controls: [
          {
            componentPath: "ConfigIconPicker",
            valuePath: "options.icon.value",
            className: "p-l-16 p-r-16 p-t-16",
            valueSource: fields.value.not_enough_container.field,
            options: {},
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.icon.color",
            valueSource: fields.value.not_enough_container.field,
            options: {
              label: "Color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.icon.size",
            valueSource: fields.value.not_enough_container.field,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Size",
              minValue: 12,
              maxValue: 32,
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.icon.gap",
            valueSource: fields.value.not_enough_container.field,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Spacing between text and icon",
              minValue: 12,
              maxValue: 32,
            },
          },
        ],
      },
      typography(
        {
          source: fields.value.not_enough_container.field.options.deposit,
          except: ["ConfigAlignment"],
          isOptionPath: false,
        },
        "Deposit"
      ),
    ];
  });

  const buttonsGroupContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.layout",
            valueSource: fields.value.button_group.field,
            className: "group-control-element",
            options: {
              isBold: true,
              label: "Layout",
              noTextWrap: true,
              items: [
                {
                  label: "Vertical",
                  value: DisplayOrientation.VERTICAL,
                },
                {
                  label: "Horizontal",
                  value: DisplayOrientation.HORIZONTAL,
                },
              ],
            },
          },
          {
            componentPath: "ConfigHorizontalSpace",
            valuePath: "options.display.gap",
            valueSource: fields.value.button_group.field,
            className: "group-control-element",
            options: {
              label: "Gap",
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              value: "button_1",
              label: "Place bet button",
            },
            {
              value: "button_2",
              label: "See more bets button",
            },
          ],
        },
      },
    ];
  });

  return {
    marketsContentControls,
    betItemsDesignControls,
    betItemsContentControls,
    betItemsStateControls,
    betsContentControls,
    betsAmountContentControls,
    betAmountsStatesControls,
    formFieldDesignStyles,
    formFieldContentStyles,
    formFieldStates,
    notEnoughFundsContentControls,
    buttonsGroupContentControls,
  };
};
