<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <!-- All controls in right sidebar -->
    <CardsListWidgetSettings
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />
    <div class="cards-list-widget">
      <BasicCardSliderField
        :widget="widget"
        :fields="fields"
        :states="states"
        style="height: 100%; align-content: stretch"
      >
        <template #slide="{ slideField }">
          <CardSlide
            :slide-field="slideField"
            :widget="widget"
            :states="states"
          />
        </template>
      </BasicCardSliderField>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { ICell } from "~~/models/grid.interface";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useInit } from "~~/composables/widgets/cards-list/useCardsListInit";
import { useStates } from "~~/composables/widgets/common/useStates";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const { init, fields, states, DEFAULT_STATES } = useInit(toRef(props.widget));

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
  resetConditionHandler: (stateName: string) => {
    const skip = [""];
    if (skip.includes(stateName)) return false;
    return true;
  },
});

init();
</script>

<style lang="scss">
.cards-list-widget {
  position: relative;
}
</style>
