import { IPageContentWidget } from "~~/models/page.model";
import { ICell } from "~~/models/grid.interface";
import { generatePromoBetsFenixWidgetCssString } from "~~/components/widgets/widgets-entities/PromoBetsFenixWidget";

import { generateTextWidgetCssString } from "./widgets/text-widget-css";
import { generateCardWidgetCssString } from "./widgets/card-widget-css";
import { generateDropdownWidgetCssString } from "./widgets/dropdown-widget-css";
import { generateItemsWidgetCssString } from "./widgets/items-widget-css";
import { generateRegisterFormWidgetCssString } from "./widgets/register-form-widget-css";
import { generateLoginFormWidgetCssString } from "./widgets/login-form-widget-css";
import { generateSetPasswordFormWidgetCssString } from "./widgets/set-password-form-widget-css";
import { generateForgotPasswordFormWidgetCssString } from "./widgets/forgot-password-form-widget-css";
import { generateChangePasswordFormWidgetCssString } from "./widgets/change-password-widget-css";
import { generateProfileDropdownWidgetCssString } from "./widgets/profile-dropdown-widget-css";
import { generateSportsMenuWidgetCssString } from "./widgets/sports-menu-widget-css";
import { generateSportsMenuPrematchFenixWidgetCssString } from "./widgets/sports-menu-prematch-fenix-css";
import { generateUserProfileFormWidgetCssString } from "./widgets/user-profile-form-widget-css";
import { generateSportsbookWidgetCssString } from "./widgets/sportsbook-widget-css";
import { generateDepositListWidgetCssString } from "./widgets/deposit-list-widget-css";
import { generateTextSliderWidgetCssString } from "./widgets/text-slider-widget-css";
import { generateEventWidgetCssString } from "./widgets/1event-widget-css";
import { generateTransactionHistoryCssString } from "./widgets/transaction-history-widget-css";
import { generateBetslipWidgetCssString } from "./widgets/betslip-widget-css";
import { generateSportsbookLiveWidgetCssString } from "./widgets/sportsbook-live-widget-css";
import { generateBetsListWidgetCssString } from "./widgets/betslist-widget-css";
import { generateLiveBetsListWidgetCssString } from "./widgets/live-betslist-widget-css";
import { generateSearchFieldWidgetCssString } from "./widgets/search-field-widget-css";
import { generateLanguagesCssString } from "./widgets/languages-widget-css";
import { generateBonusesCssString } from "./widgets/bonuses-widget-css";
import { generateTimezonesWidgetCssString } from "./widgets/timezones-widget-css";
import { generateModalWindowHeaderWidgetCssString } from "./widgets/modal-window-header-widget-css";
import { generateBreadcrumbsWidgetCssString } from "./widgets/breadcrumbs-widget-css";
import { generateBannerSliderWidgetCssString } from "./widgets/banner-slider-widget-css";
import { generateCasinoGamesListWidgetCssString } from "./widgets/casino-games-list-widget-css";
import { generateCasinoGameWidgetCssString } from "./widgets/casino-game-widget-css";
import { generateCasinoGamesFiltersWidgetCssString } from "./widgets/casino-games-filters-widget-css";
import { generateCasinoGamesSearchFieldWidgetCssString } from "./widgets/casino-games-search-field-widget-css";
import { generateGdprWidgetCssString } from "./widgets/gdpr-widget-css";
import { generatePromotedBetsWidgetCssString } from "./widgets/promoted-bets-list-css";
import { generateComingEventsWidgetCssString } from "./widgets/coming-events-css";
import { generateComingEventsSwitcherWidgetCssString } from "./widgets/coming-events-switcher-css";
import { generateSearchResultsWidgetCssString } from "./widgets/search-results-widget-css";
import { generatePopularLeaguesWidgetCssString } from "./widgets/popular-leagues-widget-css";
import { generateVirtualsWidgetCssString } from "./widgets/virtuals-widget-css";
import { generateRacingSportsbookLiveWidgetCssString } from "./widgets/racing-sportsbook-live-widget-css";
import { generateFavoritesEventsLiveWidgetCssString } from "./widgets/favorites-events-live-widget-css";
import { generateRacingEventLiveWidgetCssString } from "./widgets/racing-sports-event-live-widget-css";
import { generateHtmlEditorWidgetCssString } from "./widgets/html-editor-widget-css";
import { generateSportsbookBet9WidgetCssString } from "./widgets/sportbook-bet9-widget";
import { generateSportsMenuLiveFenixWidgetCssString } from "./widgets/sports-menu-live-fenix-css";
import { generateSportsBookMobileLiveFenixWidgetCssString } from "./widgets/sportsbook-mobile-live-fenix-widget-css";
import { generateBetsListFenixMobileWidgetCssString } from "./widgets/betslist-fenix-mobile-widget-css";
import { generateSportsbookLiveFenixWidgetCssString } from "./widgets/sportsbook-live-fenix-widget-css";
import { generateSportsBookPreMatchFenixMobileWidgetCssString } from "./widgets/sportbook-prematch-fenix-mobile-widget";
import { generateFavoritesEventsLiveFenixWidgetCssString } from "./widgets/favorites-events-live-fenix-widget-css";
import { generateBetslipFenixWidgetCssString } from "./widgets/betslip-fenix-widget-css";
import { generateCasinoMenuWidgetCssString } from "./widgets/casino-menu-widget-css";
import { generateCasinoGamesListFenixWidgetCssString } from "./widgets/casino-games-list-fenix-widget-css";
import { generatePaymentProvidersListFenixCss } from "./widgets/payment-providers-list-fenix-widget-css";
import { generateUserIdWidgetCssString } from "./widgets/user-id-widget-css";
import { generatePaymentProviderFormFenixWidgetCssString } from "./widgets/payment-provider-form-fenix-widget-css";
import { generateTransactionHistoryFenixCssString } from "./widgets/transaction-history-fenix-widget-css";
import { generateBonusesFenixCssString } from "./widgets/bonuses-fenix-widget-css";
import { generatePromotionsListCss } from "./widgets/promotions-list-widet-css";
import { generateSportEventFenixCss } from "./widgets/sport-event-fenix-css";
import { generateBetslipStatusFenixWidgetCssString } from "./widgets/betslip-status-fenix-widget-css";
import { generateSimpleTextWidgetCssString } from "./widgets/primitive/simple-text-widget-css";
import { generateButtonWidgetCssString } from "./widgets/primitive/button-widget-css";
import { generateImageWidgetCssString } from "./widgets/primitive/image-widget-css";
import { generateLoginFormFenixWidgetCssString } from "./widgets/login-form-fenix-css";
import { generateProfileDropdownFenixWidgetCssString } from "./widgets/profile-dropdown-fenix-widget-css";
import { generateContainerWidgetCssString } from "./widgets/container-widget-css";
import { generateBookingCodeWidgetCssString } from "./widgets/booking-code-widget-css";
import { generateCardFenixWidgetCssString } from "./widgets/card-fenix-widget-css";
import { generateCasinoPromotedCategoriesCssString } from "./widgets/casino-promoted-categories-widget-css";
import { generateCasinoProvidersFilterWidgetCssString } from "./widgets/casino-provider-filter-widget";
import { generateCasinoSmartGamesListWidgetCssString } from "./widgets/casino-smart-games-list-widget";
import { generateCardsListWidgetCssString } from "./widgets/cards-list-widget-css";
import { generateTopMatchFenixCssString } from "./widgets/top-match-fenix-css";
import { generatePromotionsPageWidgetCssString } from "./widgets/promotions-page-widget-css";

export const widgetGenerators: Record<
  string,
  (widget: IPageContentWidget, prfix?: string, cell?: ICell) => string
> = {
  PromoBetsFenixWidget: generatePromoBetsFenixWidgetCssString,

  TextWidget: generateTextWidgetCssString,
  CardWidget: generateCardWidgetCssString,
  DropdownWidget: generateDropdownWidgetCssString,
  ItemsWidget: generateItemsWidgetCssString,
  RegisterFormWidget: generateRegisterFormWidgetCssString,
  LoginFormWidget: generateLoginFormWidgetCssString,
  SetPasswordFormWidget: generateSetPasswordFormWidgetCssString,
  ForgotPasswordFormWidget: generateForgotPasswordFormWidgetCssString,
  ChangePasswordWidget: generateChangePasswordFormWidgetCssString,
  ProfileDropdownWidget: generateProfileDropdownWidgetCssString,
  SportsMenuWidget: generateSportsMenuWidgetCssString,
  SportsMenuLiveWidget: generateSportsMenuWidgetCssString,
  RacingSportsMenuLiveWidget: generateSportsMenuWidgetCssString,
  SportsMenuPrematchFenixWidget: generateSportsMenuPrematchFenixWidgetCssString,
  UserProfileFormWidget: generateUserProfileFormWidgetCssString,
  SportsBookWidget: generateSportsbookWidgetCssString,
  DepositListWidget: generateDepositListWidgetCssString,
  WithdrawalListWidget: generateDepositListWidgetCssString,
  TextSliderWidget: generateTextSliderWidgetCssString,
  BannerSliderWidget: generateBannerSliderWidgetCssString,
  EventWidget: generateEventWidgetCssString,
  TransactionHistoryWidget: generateTransactionHistoryCssString,
  BetslipWidget: generateBetslipWidgetCssString,
  SportsBookLiveWidget: generateSportsbookLiveWidgetCssString,
  BetsListWidget: generateBetsListWidgetCssString,
  LiveBetsListWidget: generateLiveBetsListWidgetCssString,
  SearchFieldWidget: generateSearchFieldWidgetCssString,
  LanguagesWidget: generateLanguagesCssString,
  BonusesWidget: generateBonusesCssString,
  TimezonesWidget: generateTimezonesWidgetCssString,
  ModalWindowHeaderWidget: generateModalWindowHeaderWidgetCssString,
  BreadcrumbsWidget: generateBreadcrumbsWidgetCssString,
  CasinoGamesListWidget: generateCasinoGamesListWidgetCssString,
  CasinoGameWidget: generateCasinoGameWidgetCssString,
  CasinoGamesFiltersWidget: generateCasinoGamesFiltersWidgetCssString,
  CasinoGamesSearchFieldWidget: generateCasinoGamesSearchFieldWidgetCssString,
  GdprWidget: generateGdprWidgetCssString,
  PromotedBetsListWidget: generatePromotedBetsWidgetCssString,
  ComingSportEventsWidget: generateComingEventsWidgetCssString,
  ComingEventsSwitcherWidget: generateComingEventsSwitcherWidgetCssString,
  SearchResultsWidget: generateSearchResultsWidgetCssString,
  PopularLeaguesWidget: generatePopularLeaguesWidgetCssString,
  VirtualsWidget: generateVirtualsWidgetCssString,
  RacingSportsbookLiveWidget: generateRacingSportsbookLiveWidgetCssString,
  FavouritesEventsLiveWidget: generateFavoritesEventsLiveWidgetCssString,
  FavouritesEventsLiveFenixWidget:
    generateFavoritesEventsLiveFenixWidgetCssString,
  RacingSportsEventLiveWidget: generateRacingEventLiveWidgetCssString,
  HtmlEditorWidget: generateHtmlEditorWidgetCssString,
  SportsBookPrematchFenixWidget: generateSportsbookBet9WidgetCssString,
  SportsMenuLiveFenixWidget: generateSportsMenuLiveFenixWidgetCssString,
  SportsBookMobileLiveFenixWidget:
    generateSportsBookMobileLiveFenixWidgetCssString,
  BetsListFenixMobileWidget: generateBetsListFenixMobileWidgetCssString,
  SportsBookLiveFenixWidget: generateSportsbookLiveFenixWidgetCssString,
  SportsBookPreMatchFenixMobileWidget:
    generateSportsBookPreMatchFenixMobileWidgetCssString,
  BetslipFenixWidget: generateBetslipFenixWidgetCssString,
  CasinoMenuWidget: generateCasinoMenuWidgetCssString,
  CasinoGamesListFenixWidget: generateCasinoGamesListFenixWidgetCssString,
  PaymentProvidersListFenixWidget: generatePaymentProvidersListFenixCss,
  UserIDWidget: generateUserIdWidgetCssString,
  PaymentProviderFormFenixWidget:
    generatePaymentProviderFormFenixWidgetCssString,
  TransactionHistoryFenixWidget: generateTransactionHistoryFenixCssString,
  BonusesFenixWidget: generateBonusesFenixCssString,
  PromotionsListWidget: generatePromotionsListCss,
  SportEventFenixWidget: generateSportEventFenixCss,
  BetslipStatusFenixWidget: generateBetslipStatusFenixWidgetCssString,
  SimpleTextWidget: generateSimpleTextWidgetCssString,
  ButtonWidget: generateButtonWidgetCssString,
  ImageWidget: generateImageWidgetCssString,
  LoginFormFenixWidget: generateLoginFormFenixWidgetCssString,
  ProfileDropdownFenixWidget: generateProfileDropdownFenixWidgetCssString,
  ContainerWidget: generateContainerWidgetCssString,
  BookingCodeFenixWidget: generateBookingCodeWidgetCssString,
  CardFenixWidget: generateCardFenixWidgetCssString,
  CasinoPromotedCategoriesWidget: generateCasinoPromotedCategoriesCssString,
  CasinoProvidersFilterWidget: generateCasinoProvidersFilterWidgetCssString,
  CasinoSmartGamesListFenixWidget: generateCasinoSmartGamesListWidgetCssString,
  CardsListWidget: generateCardsListWidgetCssString,
  TopMatchFenixWidget: generateTopMatchFenixCssString,
  PromotionsPageWidget: generatePromotionsPageWidgetCssString,
};
