<template>
  <div
    v-if="hasDesignPermissions"
    class="widget-control-container config-dimension-input m-t-16 p-b-16 p-l-16 p-r-16"
  >
    <ConfigRadioTextElements
      v-if="dimensionTypes.length > 1"
      class="p-t-0 p-l-0 p-b-0 p-r-0 border-bottom-none"
      :label="label"
      :items="dimensionTypes"
      :model-value="modelValue?.type"
      @update:model-value="handleUpdate($event, 'type')"
    />

    <p
      v-else
      class="m-b-8 bold"
    >
      {{ label }}
    </p>

    <ConfigDimensionValue
      v-if="modelValue?.type === ResizingType.FIXED"
      :model-value="modelValue?.value"
      :exclude="excludeValues || [Dimension.CALC]"
      :is-width="isWidth"
      :icon="icon"
      class="m-t-16"
      @update:model-value="handleUpdate($event, 'value')"
    />
  </div>
</template>

<script lang="ts" setup>
import { usePermissions } from "~~/composables/permissions/usePermissions";
import { Dimension } from "~~/models/grid.interface";
import {
  ResizingType,
  WidgetDimension,
} from "~~/models/widgets/widget-controls.model";

const props = defineProps<{
  modelValue?: WidgetDimension;
  label: string;
  icon?: string;
  exclude?: ResizingType[];
  excludeValues?: Dimension[];
  isWidth?: boolean;
}>();

const DIMENSION_TYPES = [
  {
    label: "Fixed",
    value: ResizingType.FIXED,
  },
  {
    label: "Fill",
    value: ResizingType.FILL,
  },
  {
    label: "Hug",
    value: ResizingType.HUG,
  },
];

const emit = defineEmits<{
  (e: "update:modelValue", event: WidgetDimension): void;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const dimensionTypes = computed<Array<Record<any, ResizingType | string>>>(
  () => {
    if (props.exclude) {
      return DIMENSION_TYPES.filter(
        item => !props.exclude?.includes(item.value)
      );
    }
    return DIMENSION_TYPES;
  }
);

const handleUpdate = (
  value: Partial<WidgetDimension>,
  name: keyof WidgetDimension
) => {
  emit("update:modelValue", {
    ...(props.modelValue || {}),
    [name]: value,
  });
};
</script>
