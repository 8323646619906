import {
  IFillControl,
  FillType,
  IShadowControl,
  SpacingKeyName,
  ISpacingInput,
  IBorderControl,
  TextStyle,
  Alignment,
  WidgetSize,
  WidgetDimension,
  VerticalAlignment,
  IHeadingControl,
  IImagePosition,
  ICornerRadiusControl,
  BorderStrokeType,
  ICornerValueComplex,
  DisplayOrientation,
  Display,
  MinMaxDimension,
  Align,
} from "~~/models/widgets/widget-controls.model";
import {
  ICellPosition,
  CellPosition,
  ICellHeight,
  CellSizeType,
  ICellDimensionValue,
  Dimension,
  Sizing,
  ICellContentSettings,
  CellLayout,
  ICellOptions,
} from "~~/models/grid.interface";
import {
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import useImageCdnChange from "~~/composables/useImageCdnChange";
import { getDefaultSpacing } from "~~/constants/configs/common/widget-initial";
import { displayOrientationToFlexDirection } from "~~/constants/dictionaries";

import { getPxValueFromNumber, getSizeValue, styleObjectToString } from "..";
import {
  getColorFromHex,
  getBoxShadowStyle,
  getTextShadowStyle,
  getBorderStyle,
  fetchColorFromRgba,
  rgbaToHash,
} from "../widget-settings";

export const generateFillColorStyle = (
  value: IFillControl | undefined,
  important?: boolean
): string => {
  if (!value?.color)
    return `background: unset ${important ? "!important" : ""};`;
  return `background:${getColorFromHex(value)} ${
    important ? "!important" : ""
  };`;
};

export const generateTextColor = (
  value: IFillControl | undefined,
  important?: boolean
): string => {
  if (!value?.color) return "color: unset;";
  return `color:${getColorFromHex(value)} ${important ? "!important" : ""};`;
};

export const generateTextDecorationColor = (
  value: IFillControl | undefined,
  important?: boolean
): string => {
  if (!value?.color)
    return `text-decoration-color: initial ${important ? "!important" : ""};`;
  const rgba = fetchColorFromRgba(getColorFromHex(value) as string);
  const hashColor = rgbaToHash(rgba!.r, rgba!.g, rgba!.b, rgba!.a);

  return `text-decoration-color:${hashColor} !important;`;
};

export const generateFillImageColorStyle = (
  value: {
    fillType: FillType;
    value: IFillControl | string;
    position: IImagePosition;
  },
  important?: boolean
): string => {
  if (!value.value && value.fillType === FillType.COLOR) return "";
  if (value.fillType === FillType.COLOR) {
    return generateFillColorStyle(value.value as IFillControl, important);
  }

  const backgroundStyle = value.value
    ? generateBackgroundUrl(String(value.value))
    : "";

  return `${backgroundStyle}${generateBackgroundSize(
    "cover",
    true
  )}${generateBackgroundRepeat("no-repeat", true)}${generateBackgroundPosition(
    value.position,
    true
  )}`;
};

export const generateBackgroundUrl = (value: string): string => {
  return `background:url('${useImageCdnChange(value)}');`;
};

export const generateBackgroundSize = (
  value: string,
  important?: boolean
): string => {
  return `background-size:${value} ${important ? "!important" : ""};`;
};

export const generateBackgroundRepeat = (
  value: string,
  important?: boolean
): string => {
  return `background-repeat:${value} ${important ? "!important" : ""};`;
};

export const generateBackgroundPosition = (
  value?: IImagePosition,
  important?: boolean
): string => {
  if (!value) {
    return ``;
  }

  if (value?.value) {
    return `background-position:${value.value} ${
      important ? "!important" : ""
    };`;
  }

  return `background-position:${value.x || 0}% ${value.y || 0}% ${
    important ? "!important" : ""
  };`;
};

export const generateShadowStyle = (
  value: IShadowControl | null,
  important?: boolean
): string => {
  if (!value?.fill.color) return "box-shadow:none;";
  return `box-shadow:${getBoxShadowStyle(value).boxShadow} ${
    important ? "!important" : ""
  };`;
};

export const generateTextShadowStyle = (
  value: IShadowControl | null,
  important?: boolean
): string => {
  if (!value?.fill.color)
    return `text-shadow:none ${important ? "!important" : ""};`;
  return `text-shadow:${getTextShadowStyle(value).textShadow} ${
    important ? "!important" : ""
  };`;
};

export const generateMarginStyle = (value: Partial<ISpacingInput>) => {
  const { top, bottom, left, right } = value || {};
  let cssString = "";
  if (top) {
    cssString += `margin-top:${top}px;`;
  }
  if (bottom) {
    cssString += `margin-bottom:${bottom}px;`;
  }
  if (left) {
    cssString += `margin-left:${left}px;`;
  }
  if (right) {
    cssString += `margin-right:${right}px;`;
  }
  return cssString;
};

export const generatePaddingStyle = (value: ISpacingInput) => {
  const { top, right, bottom, left } = value || {};
  return `padding:${generateSpacingValues(top, right, bottom, left)};`;
};

export const generateSpacingStyle = (
  value: Record<SpacingKeyName, Partial<ISpacingInput>>
) => {
  const marginValues = value[SpacingKeyName.MARGIN] || {};
  const paddingValues = value[SpacingKeyName.PADDING] || {};
  const marginValue: Partial<ISpacingInput> = {
    top: marginValues.top,
    bottom: marginValues.bottom,
    left: marginValues.left,
    right: marginValues.right,
  };
  return `${generateMarginStyle(marginValue)};padding:${generateSpacingValues(
    paddingValues.top,
    paddingValues.right,
    paddingValues.bottom,
    paddingValues.left
  )};`;
};

const generateSpacingValues = (...values: any) => {
  return values.map(getPxValueFromNumber).join(" ");
};

export const generateBorderStyle = (
  value: IBorderControl | null,
  important?: boolean
): string => {
  const { borderColor, borderWidth, borderStyle, ...args } =
    getBorderStyle(value);

  const styleProperties = [];

  styleProperties.push(
    `border-style:${borderStyle || "none"} ${important ? "!important" : ""};`
  );

  if (borderWidth) {
    styleProperties.push(
      `border-width:${borderWidth || "0px"} ${important ? "!important" : ""};`
    );
  }

  if (borderColor) {
    styleProperties.push(
      `border-color:${borderColor || "trasparent"} ${
        important ? "!important" : ""
      };`
    );
  }

  if (value?.stroke?.type === BorderStrokeType.CUSTOM) {
    for (const key in args) {
      styleProperties.push(`${key}:${args[key]};`);
    }
  }

  return styleProperties.join("");
};

export const generateCornerRadiusStyle = (
  cornerRadius: ICornerRadiusControl | string | number,
  important?: boolean
): string => {
  if (typeof cornerRadius === "string" || typeof cornerRadius === "number") {
    return `border-radius: ${getPxValueFromNumber(cornerRadius)} ${
      important ? " !important" : ""
    };`;
  }

  let style = "border-radius:";

  if (cornerRadius.type === BorderStrokeType.CUSTOM) {
    let styleList = "";

    for (const key of ["topLeft", "topRight", "bottomLeft", "bottomRight"]) {
      const value = getPxValueFromNumber(
        (cornerRadius.value as ICornerValueComplex)[
          key as keyof ICornerValueComplex
        ]
      );

      styleList += `${value} `;
    }

    style += styleList;
  } else {
    style += getPxValueFromNumber(cornerRadius.value as string);
  }

  if (important) {
    style += " !important";
  }

  style += ";";

  return style;
};

export const generateDecorationStyle = (
  value: TextStyle[],
  important?: boolean
): string => {
  const fontWeight = value.includes(TextStyle.BOLD) ? "bold" : "unset";
  const fontStyle = value.includes(TextStyle.ITALIC) ? "italic" : "unset";
  const importantValue = important ? "!important" : "";

  const textDecorations = [];

  if (value.includes(TextStyle.UNDERLINE)) {
    textDecorations.push("underline");
  }

  if (value.includes(TextStyle.STRIKE)) {
    textDecorations.push("line-through");
  }

  const textDecorationValue =
    textDecorations.length > 0 ? textDecorations.join(" ") : "unset";

  return `font-weight:${fontWeight} ${importantValue};font-style:${fontStyle} ${importantValue};text-decoration:${textDecorationValue} ${importantValue};`;
};

export const generateTextAlignStyle = (
  value: Alignment,
  important?: boolean
): string => `text-align:${value} ${important ? "!important" : ""};`;

export const generateFontFamily = (
  value: string,
  important?: boolean
): string => `font-family:${value} ${important ? "!important" : ""};`;

export const generateFontSize = (
  value: string | number | IHeadingControl,
  important?: boolean
): string => {
  if (typeof value === "object" && value !== null) {
    return `font-size:${getPxValueFromNumber(value.value)} ${
      important ? "!important" : ""
    };`;
  }
  return `font-size:${getPxValueFromNumber(value)} ${
    important ? "!important" : ""
  };`;
};

export const generateHeight = (
  value: string | number,
  important?: boolean
): string =>
  `height:${getPxValueFromNumber(value)} ${important ? "!important" : ""};`;

export const generateWidth = (
  value: string | number,
  unit: "px" | "%" | null,
  important?: boolean
): string => `width:${value}${unit || ""} ${important ? "!important" : ""};`;

export const generateJustifyContent = (value: string): string =>
  `justify-content:${value};`;

export const generateJustifyItems = (value: string): string =>
  `justify-items:${value};`;

export const generateObjectFit = (value: string): string =>
  `object-fit:${value};`;

export const generateGap = (value: string | number | undefined): string => {
  if (!value) return "gap: unset;";
  return `gap:${getPxValueFromNumber(value)};`;
};

export const generateRowGap = (value: string | number | undefined): string => {
  if (!value) return "";
  return `row-gap:${getPxValueFromNumber(value)};`;
};

export const generateColumnGap = (
  value: string | number | undefined
): string => {
  if (!value) return "";
  return `column-gap:${getPxValueFromNumber(value)};`;
};

export const generateWrap = (value: string | undefined): string => {
  if (!value) return "flex-wrap: unset;";
  return `flex-wrap:${value};`;
};

export const generateAlignItems = (value: string): string => {
  return `align-items:${value};`;
};

export const generateDisplayValue = (value: string): string =>
  `display:${value};`;

export const generateFlexDirection = (value: string | undefined): string => {
  if (!value) return "flex-direction: unset;";
  return `flex-direction:${value};`;
};

export const generateAlignSelf = (value: string | undefined): string => {
  if (!value) return "align-self: unset;";
  return `align-self:${value};`;
};

type FlexGeneratorArg = {
  flex: string;
  align: string;
  justify: string;
  direction?: string;
  gap?: string;
  rowGap?: string;
  wrap?: string;
  alignSelf?: string;
};

export const generateFlex = (arg: FlexGeneratorArg): string =>
  `${generateDisplayValue(arg.flex)}${generateJustifyContent(
    arg.justify
  )}${generateAlignItems(arg.align)}${generateGap(arg.gap)}${generateRowGap(
    arg.rowGap
  )}${generateWrap(arg.wrap)}${generateFlexDirection(
    arg.direction
  )}${generateAlignSelf(arg.alignSelf)}`;

export const generateFlexAlignment = (alignment: Alignment): string => {
  if (alignment === Alignment.CENTER) {
    return "center";
  } else if (alignment === Alignment.RIGHT) {
    return "flex-end";
  } else {
    return "flex-start";
  }
};

export const generateMaxWidth = (value: string, important?: boolean): string =>
  `max-width:${value} ${important ? "!important" : ""};`;

type GridGeneratorArg = {
  templateColumns?: string;
  templateRows?: string;
  autoColumns?: string;
  autoRows?: string;
  gridAutoFlow?: string;
  rowGap?: string;
  columnGap?: string;
  gap?: string;
  alignItems?: string;
};

export const generateGridColumnsValue = (value: string) => {
  return `grid-template-columns: ${value};`;
};

export const generateGridRowsValue = (value: string) => {
  return `grid-template-rows: ${value};`;
};

export const generateGridAutoColumnsValue = (value: string) => {
  return `grid-auto-columns: ${value};`;
};

export const generateGridAutoRowsValue = (value: string) => {
  return `grid-auto-rows: ${value};`;
};

export const generateGridAutoFlow = (value: string) => {
  return `grid-auto-flow: ${value};`;
};

export const generateGrid = (arg: GridGeneratorArg): string =>
  `${generateDisplayValue("grid")}${
    arg.templateColumns ? generateGridColumnsValue(arg.templateColumns) : ""
  }${
    arg.templateRows ? generateGridRowsValue(arg.templateRows) : ""
  }${generateGap(arg.gap)}${generateRowGap(arg.rowGap)}${generateColumnGap(
    arg.columnGap
  )} ${arg.gridAutoFlow ? generateGridAutoFlow(arg.gridAutoFlow) : ""}${
    arg.autoColumns ? generateGridAutoColumnsValue(arg.autoColumns) : ""
  }${arg.autoRows ? generateGridAutoRowsValue(arg.autoRows) : ""} ${
    arg.alignItems ? generateAlignItems(arg.alignItems) : ""
  }`;

export const generateMinWidth = (value: string): string =>
  `min-width:${value};`;

export const generateVerticalPlacement = (
  placement: CellPosition,
  offset = "0"
): string => {
  if (placement === CellPosition.BOTTOM) {
    return `bottom: ${getPxValueFromNumber(offset)};`;
  }

  return `top: ${getPxValueFromNumber(offset)};`;
};

export const generatePosition = (position: ICellPosition): string => {
  if (!position || !position.fixed) {
    return "";
  }

  const cellPosition =
    position.position === CellPosition.TOP ? "sticky" : "fixed";

  return `position: ${cellPosition};left:0;width:100%;z-index:100;${generateVerticalPlacement(
    position.position
  )}`;
};

export const generateMaxHeight = (height: string): string => {
  if (!height) {
    return "";
  }

  return `max-height: ${height};overflow:auto;`;
};

const getCellHeightValue = (height?: ICellDimensionValue): string => {
  if (!height) {
    return "initial;";
  }

  if (height.type === Dimension.CALC) {
    return `calc(${height.value});`;
  }

  if (height.type === Dimension.CUSTOM) {
    return height.value;
  }

  return `${height.value}${height.type};`;
};

export const generateCellHeight = (height: ICellHeight): string => {
  if (!height) {
    return "";
  }

  if (height.type === CellSizeType.FILL) {
    let style = `min-height: 100%;`;
    style += "align-self:stretch;";

    return style;
  }

  if (height.type === CellSizeType.HUG) {
    return `height: auto;`;
  }

  if (height.isMax) {
    return `height: initial;overflow:auto;max-height: ${getCellHeightValue(
      height.height
    )}`;
  }

  if (height.isMin) {
    return `height: initial;min-height: ${getCellHeightValue(height.height)}`;
  }

  return `overflow:auto;height: ${getCellHeightValue(height.height)}`;
};

type ContentWidth = {
  type: Sizing;
  width: string | number;
  alignment: Alignment;
};

export const generateContentWidth = (contentWidth: ContentWidth) => {
  if (contentWidth.type === Sizing.ADAPTIVE) {
    return "width: 100%;";
  }

  const baseStyle = `max-width: ${getPxValueFromNumber(
    contentWidth.width
  )}; width: ${getPxValueFromNumber(contentWidth.width)};`;

  if (contentWidth.alignment === Alignment.LEFT) {
    return baseStyle + "margin-right: auto;";
  }

  if (contentWidth.alignment === Alignment.RIGHT) {
    return baseStyle + "margin-left: auto;";
  }

  return baseStyle + "margin-left: auto;margin-right: auto;";
};

export const generateWidgetWidth = (width?: WidgetDimension): string => {
  if (!width) {
    return "";
  }

  if (width.type === CellSizeType.HUG) {
    return "width: initial;display: inline-block;";
  }

  if (width.type === CellSizeType.FILL) {
    return "width: 100%;";
  }

  return `width: ${width.value?.value}${width.value?.type};max-width: ${width.value?.value}${width.value?.type};`;
};

const getNumberValue = (value: any): number => {
  if (value) {
    return Number(value);
  }

  return 0;
};

const calculateMarginValue = (margin: ISpacingInput, type = "horizontal") => {
  if (type === "horizontal") {
    return getNumberValue(margin.left) + getNumberValue(margin.right);
  }

  return getNumberValue(margin.top) + getNumberValue(margin.bottom);
};

const getWidgetMarginValue = (options: IWidgetOptions): IWidgetOptions => {
  if (options.spacing?.margin) {
    return options.spacing.margin;
  }

  if (options.spacing?.margins) {
    return options.spacing.margins;
  }

  if (options.margins) {
    return options.margins;
  }

  return options.margin || getDefaultSpacing();
};

export const generateBaseWidgetWidth = (width?: WidgetDimension): string => {
  if (!width) {
    return "";
  }

  if (width.type === CellSizeType.HUG) {
    return "width: initial;display: inline-block;";
  }

  if (width.type === CellSizeType.FILL) {
    return "width: 100%;";
  }

  return "width: 100%;";
};

export const generateWidgetWrapperMaxWidth = (
  options?: IWidgetOptions,
  maxWidthValue?: string,
  maxWidthType?: string
): string => {
  if (!options || !maxWidthValue) {
    return "";
  }

  if (maxWidthType === Dimension.PX) {
    return `max-width:${maxWidthValue}${maxWidthType};`;
  }

  const margin = getWidgetMarginValue(options);

  return `max-width:calc(${maxWidthValue}${maxWidthType} - ${getPxValueFromNumber(
    calculateMarginValue(margin as ISpacingInput)
  )});`;
};

const generateMinMaxCss = (
  minMax: MinMaxDimension | null,
  key: "width" | "height"
) => {
  if (!minMax || !minMax._active) {
    return "";
  }

  if (minMax.mode === "min") {
    return `min-${key}:${getSizeValue(minMax.min as ICellDimensionValue)};`;
  }

  if (minMax.mode === "max") {
    return `max-${key}:${getSizeValue(minMax.max as ICellDimensionValue)};`;
  }

  return (
    `min-${key}:${getSizeValue(minMax.min as ICellDimensionValue)};` +
    `max-${key}:${getSizeValue(minMax.max as ICellDimensionValue)};`
  );
};

export const generateWidgetWrapperWidth = (
  width?: WidgetDimension,
  cellOptions?: ICellOptions,
  options?: IWidgetOptions,
  parentWidget?: IWidgetWithFields | null,
  hasChildren?: boolean
): string => {
  if (!width) {
    return "";
  }

  const minWidthCss = hasChildren ? "min-width:0;" : "";
  let minMaxCss = "";

  if (width.minMax) {
    minMaxCss = generateMinMaxCss(width.minMax, "width");
  }

  if (width.type === CellSizeType.FILL) {
    const layout =
      parentWidget?.options?.display?.layout ||
      cellOptions?.cellContentSettings?.layout;

    let css = "max-width:100%;" + minWidthCss;

    if (layout !== CellLayout.HORIZONTAL) {
      css += "align-self:stretch;";
    }

    /*
      If layout is row or wrap or widget is child
    */
    if ((layout && layout !== CellLayout.VERTICAL) || parentWidget) {
      css += "flex: 1;flex-basis: 100%;" + minWidthCss;
    }

    return css + minMaxCss;
  }

  if (width.type === CellSizeType.HUG) {
    return "width:initial;display:inline-block;" + minMaxCss;
  }

  const maxWidth = generateWidgetWrapperMaxWidth(
    options,
    width.value?.value,
    width.value?.type
  );

  // return `width: ${width.value?.value}${width.value?.type};max-width: ${width.value?.value}${width.value?.type};flex-direction:column;`;
  return `width: ${width.value?.value}${width.value?.type};flex-direction:column;${maxWidth};flex-basis:100%;flex-shrink:0;${minWidthCss}${minMaxCss}`;
};

export const generateWidgetWrapperHeight = (
  height?: WidgetDimension,
  cellOptions?: ICellOptions,
  parentWidget?: IWidgetWithFields | null
): string => {
  if (!height || height.type !== CellSizeType.FILL) {
    return "";
  }

  const layout =
    parentWidget?.options?.display?.layout ||
    cellOptions?.cellContentSettings?.layout;

  let css = "align-self:stretch;";

  if (!layout || layout === CellLayout.VERTICAL) {
    css += "flex: 1;";
  }

  return css;
};

export const generateWidgetHeight = (
  height?: WidgetDimension,
  cellOptions?: ICellOptions
): string => {
  if (!height) {
    return "";
  }
  let minMaxCss = "";

  if (height.minMax) {
    minMaxCss = generateMinMaxCss(height.minMax, "height");
  }

  if (height.type === CellSizeType.FILL) {
    if (cellOptions?.cellContentSettings?.layout !== CellLayout.VERTICAL) {
      return "height: 100%;" + minMaxCss;
    }

    return "flex: 1;" + minMaxCss;
  }

  if (height.type === CellSizeType.HUG) {
    return "height: initial;" + minMaxCss;
  }

  return (
    `height: ${height.value?.value}${height.value?.type};overflow:hidden;` +
    minMaxCss
  );
};

export const generateSize = (
  size: WidgetSize,
  cellOptions?: ICellOptions
): string => {
  if (!size) {
    return "";
  }

  return (
    generateWidgetWidth(size.width) +
    generateWidgetHeight(size.height, cellOptions)
  );
};

export const generateBaseWidgetSize = (
  size: WidgetSize,
  cellOptions?: ICellOptions
): string => {
  if (!size) {
    return "";
  }

  return (
    generateBaseWidgetWidth(size.width) +
    generateWidgetHeight(size.height, cellOptions)
  );
};

export const generateCellContentPosition = (
  cellContentSettings?: ICellContentSettings
): string => {
  if (!cellContentSettings) {
    return "";
  }

  let result = "";

  if (
    !cellContentSettings.layout ||
    cellContentSettings.layout === CellLayout.VERTICAL
  ) {
    result += "flex-direction: column;";
  } else {
    result += "flex-direction: row;";
  }

  if (cellContentSettings.layout === CellLayout.WRAP) {
    result += "flex-wrap: wrap;";
  }

  result += `gap: ${getPxValueFromNumber(cellContentSettings.gap)};`;

  if (cellContentSettings.overflow) {
    result += `overflow-x: ${cellContentSettings.overflow};`;
  }

  const flexAlignArr = <Align[]>cellContentSettings?.alignment?.split(" ");

  result += styleObjectToString({
    "justify-content": flexAlignArr?.[0],
    "align-items": flexAlignArr?.[1],
  });

  return result;
};

export const generateBaseDisplaySettings = (
  baseDisplaySettings: {
    alignment: VerticalAlignment;
  },
  _?: ICellOptions,
  hasChildren?: boolean,
  widgetOptions?: IWidgetOptions
): string => {
  if (!hasChildren) {
    return `display: flex; flex-direction: column; align-items: stretch; justify-content: ${baseDisplaySettings.alignment};`;
  }

  const alignment = widgetOptions?.display.alignment.split(" ");
  const align = alignment[0];

  return `display: flex; flex-direction: column; align-items: stretch; justify-content: ${align};`;
};

export const generateInvisibleScrollCssString =
  (options: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += `.${options._cssClass} {overflow: auto !important;scrollbar-width: none;-ms-overflow-style: none; }`;
    cssString += `.${options._cssClass}::-webkit-scrollbar {
      display: none;
    }`;

    cssString += `.${options._cssClass} {
      scroll-behavior: smooth;
    }`;

    // cssString += `.${options._cssClass} > * {
    //   scroll-snap-align: start;
    // }`;

    return cssString;
  };

export const generateLineClampStyleCssString =
  (options: IWidgetOptions, maxlines = 2) =>
  (cssString: string): string => {
    cssString +=
      `.${options._cssClass} {` +
      " display: -webkit-box;" +
      `-webkit-line-clamp: ${maxlines};` +
      "-webkit-box-orient: vertical;" +
      "overflow: hidden;" +
      "text-overflow: ellipsis;" +
      "}";

    return cssString;
  };

export const generateBreakWordStyleCssString =
  (options: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += `.${options._cssClass} {` + "word-break: break-word;" + "}";

    return cssString;
  };

export const generateDisplayOrientation = (
  displayOrientation: DisplayOrientation
) => {
  return [
    `display:${Display.FLEX};`,
    `flex-direction:${displayOrientationToFlexDirection[displayOrientation]};`,
  ].join("");
};
