<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
    :current-state="State.DEFAULT"
  >
    <BetslipFenixWidgetSettings
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />
    <div
      class="betslip-widget"
      :class="{
        'betslip-widget--full-height':
          states[widget.name] !== State.BOOKING_CODE &&
          states.bets !== State.INACTIVE_BETS,
      }"
    >
      <BetslipFenixHeaderContainer
        :widget="widget"
        :fields="fields"
        :states="states"
      />

      <div class="betslip-widget__content-container">
        <BetslipTabsContainer
          v-if="states[widget.name] === State.MY_BETS"
          :widget="widget"
          :fields="fields"
          :states="states"
        />

        <WidgetField
          v-if="
            fields.bets_container.active &&
            states[widget.name] !== State.NO_BETS &&
            states.bets === State.ACTIVE_BETS
          "
          :widget="widget"
          class="betslip-widget__betslip-cards-container"
          :class="{
            'betslip-widget__betslip-cards-container--single-bets-view':
              states.bets_container === State.SINGLE_BETS ||
              states[widget.name] === State.BETS_RESULT,
          }"
          :style="betslipCardContainerInlineStyles"
          :field="fields.bets_container.field"
        >
          <div
            v-if="states[widget.name] === State.LOADER"
            class="betslip-widget__betslip-loader-container"
            :style="loaderContainerInlineStyles"
          >
            <WidgetField
              :widget="widget"
              :field="fields.loader.field"
            >
              <span
                class="betslip-widget__loader"
                :style="loaderInlineStyles"
              ></span>
            </WidgetField>
          </div>

          <div
            v-if="
              states[widget.name] === State.BETSLIP ||
              states[widget.name] === State.LOADER
            "
          >
            <BetslipFenixCollapse
              :widget="widget"
              :fields="fields"
              :states="states"
              :cards-count="2"
              title="Single Bets"
            >
              <div>
                <BetslipCardsContainer
                  :widget="widget"
                  :fields="fields"
                  :states="states"
                  :not-available-field="fields.not_available_status_title.field"
                />

                <WidgetField
                  :widget="widget"
                  :field="fields.stake_info.field"
                >
                  <BasicDesignWrapperField
                    :widget="widget"
                    :field="fields.stake_info.field"
                  >
                    <BetslipForm
                      :widget="widget"
                      :fields="fields"
                      :states="states"
                      :is-fenix="true"
                      :stake-info="fields.stake_info.field"
                      class="betslip-widget__form"
                    >
                      <WidgetField
                        :widget="widget"
                        :field="fields.total_stake.field"
                      >
                        <BetslipEachWayTotalStake
                          :widget="widget"
                          :field="fields.total_stake.field"
                        />
                      </WidgetField>
                    </BetslipForm>
                  </BasicDesignWrapperField>
                </WidgetField>
              </div>
            </BetslipFenixCollapse>

            <BetslipFenixCollapse
              :widget="widget"
              :fields="fields"
              :states="states"
              :cards-count="1"
              title="Multi Bet"
              :tooltip-field="fields.multibet_tooltip.field"
            >
              <WidgetField
                v-if="
                  states[widget.name] === State.BETSLIP ||
                  states[widget.name] === State.LOADER
                "
                :widget="widget"
                :field="fields.stake_info.field"
              >
                <BasicDesignWrapperField
                  :widget="widget"
                  :field="fields.stake_info.field"
                >
                  <BetslipForm
                    :widget="widget"
                    :fields="fields"
                    :states="states"
                    :is-fenix="true"
                    :stake-info="fields.stake_info.field"
                    mode="multibet"
                    class="betslip-widget__form"
                  />
                </BasicDesignWrapperField>
              </WidgetField>
            </BetslipFenixCollapse>

            <BetslipFenixCollapse
              :widget="widget"
              :fields="fields"
              :states="states"
              :cards-count="1"
              title="System Bet"
            >
              <WidgetField
                v-if="
                  states[widget.name] === State.BETSLIP ||
                  states[widget.name] === State.LOADER
                "
                :widget="widget"
                :field="fields.stake_info.field"
              >
                <BasicDesignWrapperField
                  :widget="widget"
                  :field="fields.stake_info.field"
                >
                  <BetslipForm
                    :widget="widget"
                    :fields="fields"
                    :states="states"
                    :show-system-bet="true"
                    :is-fenix="true"
                    :stake-info="fields.stake_info.field"
                    mode="system"
                    class="betslip-widget__form"
                  >
                    <WidgetField
                      :widget="widget"
                      :field="fields.total_stake.field"
                    >
                      <BetslipEachWayTotalStake
                        :widget="widget"
                        :field="fields.total_stake.field"
                      />
                    </WidgetField>
                  </BetslipForm>
                </BasicDesignWrapperField>
              </WidgetField>
            </BetslipFenixCollapse>
          </div>

          <template v-if="states[widget.name] === State.MY_BETS">
            <div :style="cardsContainerElementsStyle">
              <BetslipCardsContainer
                v-for="betType in ['Single Bet', 'Multi Bet', 'System Bet']"
                :key="betType"
                :widget="widget"
                :fields="fields"
                :states="states"
                :not-available-field="fields.not_available_status_title.field"
                :bet-type="betType"
              />
            </div>
          </template>

          <template v-if="states[widget.name] === State.BOOKING_CODE">
            <WidgetField
              :widget="widget"
              :field="fields.booking_label.field"
            >
              <BasicTitleField
                :field="fields.booking_label.field"
                value="Label"
              />
            </WidgetField>

            <WidgetField
              :widget="widget"
              :field="fields.booking_input.field"
            >
              <BetslipInput
                :widget="widget"
                :fields="fields"
                :states="states"
                :state="states.fields_styling"
                :field="fields.booking_input.field"
                placeholder="Enter"
              />
            </WidgetField>

            <WidgetField
              :widget="widget"
              :field="fields.booking_error.field"
              :style="statusMessageStyles"
            >
              <CommonIcon
                v-if="fields.booking_error.field?.options.icon._active"
                :style="statusMessageIconStyles"
                :name="fields.booking_error.field?.options.icon.value"
              />

              <span :style="statusMessageTextStyles"> Error </span>
            </WidgetField>

            <WidgetField
              :widget="widget"
              :field="fields.booking_button.field"
            >
              <BasicButtonField
                :field="fields.booking_button.field"
                :current-state="states.booking_button"
                custom-value="Button"
              />
            </WidgetField>
          </template>

          <template v-if="states[widget.name] === State.BETS_RESULT">
            <div>
              <BetslipSuccessMessageContainer
                :widget="widget"
                :fields="fields"
                :states="states"
              />

              <div :style="cardsContainerElementsStyle">
                <BetslipCardsContainer
                  v-for="betType in ['Single Bet', 'Multi Bet', 'System Bet']"
                  :key="betType"
                  :widget="widget"
                  :fields="fields"
                  :states="states"
                  :not-available-field="fields.not_available_status_title.field"
                  :bet-type="betType"
                  :is-system="betType === 'System Bet'"
                />
              </div>
            </div>
          </template>
        </WidgetField>

        <BetslipBetsResultContainer
          v-if="
            states[widget.name] === State.BETS_RESULT &&
            states.bets === State.ACTIVE_BETS
          "
          :widget="widget"
          :fields="fields"
          :states="states"
        />

        <BetslipResultContainer
          v-if="
            states.bets === State.ACTIVE_BETS &&
            (states[widget.name] === State.BETSLIP ||
              states[widget.name] === State.LOADER)
          "
          :widget="widget"
          :fields="fields"
          :states="states"
        />

        <div
          v-if="
            states[widget.name] === State.NO_BETS ||
            states.bets === State.INACTIVE_BETS
          "
        >
          <WidgetField
            v-if="currentDescriptionField.active"
            :widget="widget"
            :field="currentDescriptionField.field"
            :style="betslipCardContainerInlineStyles"
            class="basic-deposit-list-widget__field"
          >
            <BasicDescriptionField
              ref="descriptionField"
              :widget="widget"
              :fields="[currentDescriptionField.field]"
            />
          </WidgetField>
        </div>
      </div>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { ICell } from "~~/models/grid.interface";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import {
  DisplayOrientation,
  SpacingKeyName,
  State,
} from "~~/models/widgets/widget-controls.model";
import { useBetslipInitData } from "~~/composables/widgets/betslip-fenix/useBetslipFenixInitData";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { ElementStyle } from "~~/models/common";
import { getCommonStyles, getTextCommonStyles } from "~~/assets/utils/styles";
import {
  getSpacing,
  getBorderStyle,
  getCornerRadiusStyle,
  getBackgroundColorStyle,
  getColorFromHex,
  getFontSizeStyle,
  getColorStyle,
} from "~~/assets/utils/widget-settings";
import { useStates } from "~~/composables/widgets/common/useStates";
import { getPxValueFromNumber } from "~~/assets/utils";
import { generateFlexAlignment } from "~~/assets/utils/widget-css/helpers";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const fields = useWidgetFields(props.widget);

const { states, DEFAULT_STATES, init } = useBetslipInitData(props.widget);

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
  resetConditionHandler(stateName) {
    if (stateName === props.widget.name || ["bets"].includes(stateName)) {
      return false;
    }

    return true;
  },
});

const betslipCardContainerInlineStyles = computed<ElementStyle>(() => {
  const betslipCardsContainerOptions =
    fields.value.bets_container.field.options;

  return {
    ...getCommonStyles(betslipCardsContainerOptions),
    ...getSpacing(betslipCardsContainerOptions.margins, SpacingKeyName.MARGIN),
    ...getBorderStyle(betslipCardsContainerOptions.border),
    gap: getPxValueFromNumber(
      fields.value.bets_container.field.options.distance
    ),
    ...getCornerRadiusStyle(betslipCardsContainerOptions.cornerRadius),
  };
});

const loaderContainerInlineStyles = computed<ElementStyle>(() => {
  return getBackgroundColorStyle(fields.value.loader.field.options.fill);
});

const loaderInlineStyles = computed<ElementStyle>(() => {
  const loaderOptions = fields.value.loader.field.options;
  return {
    width: getPxValueFromNumber(loaderOptions.stroke),
    height: getPxValueFromNumber(loaderOptions.stroke),
    border: `${getPxValueFromNumber(
      loaderOptions.size
    )} solid ${getColorFromHex(loaderOptions.backgroundColor)}`,
    borderTopColor: getColorFromHex(loaderOptions.progressColor),
    borderRightColor: getColorFromHex(loaderOptions.progressColor),
  } as ElementStyle;
});

const linkedDescription: Record<string, string> = {
  [State.BETSLIP]: "no_bets_description",
  [State.MY_BETS]: "no_bets_description_mybets",
};

const currentDescriptionField = computed(() => {
  return fields.value[linkedDescription[states.value[props.widget.name]]];
});

const cardsContainerElementsStyle = computed<ElementStyle>(() => {
  const options = fields.value.bets_container.field.options;
  return {
    display: "flex",
    flexDirection: "column",
    gap: getPxValueFromNumber(options?.distance),
  };
});

const statusMessageStyles = computed<ElementStyle>(() => {
  const field = fields.value.booking_error.field;

  if (!field) return {};

  return {
    ...getCommonStyles(field.options),
    display: "flex",
    gap: getPxValueFromNumber(field.options.distance),
    justifyContent: "center",
    flexDirection:
      field.options.layout === DisplayOrientation.VERTICAL ? "column" : "row",
    alignItems:
      field.options.layout === DisplayOrientation.HORIZONTAL
        ? field.options.verticalAlignment
        : generateFlexAlignment(field.options.horizontalAlignment),
  };
});

const statusMessageIconStyles = computed<ElementStyle>(() => {
  const field = fields.value.booking_error.field;

  if (!field) return {};

  return {
    ...getColorStyle(field.options.icon.textColor),
    ...getFontSizeStyle(field.options.icon.iconSize),
  };
});

const statusMessageTextStyles = computed<ElementStyle>(() => {
  const field = fields.value.booking_error.field;

  if (!field) return {};

  return {
    ...getTextCommonStyles(field.options),
  };
});

init();
</script>

<style lang="scss">
.betslip-widget {
  &__header-items-container {
    @include flex(center, space-around, 0px);
  }

  &__header-item-container {
    @include flex(center, space-around, 0px);
  }

  &__header-item {
    @include flex(center, center, 0px);
  }
}

.betslip-widget {
  &--full-height {
    min-height: 1060px;
  }

  @include flex-column(unset, unset, 0px);

  &__cards-form {
    display: flex;
  }

  &__form-container {
    @include flex-column(stretch, flex-start, 0);

    &--horizontal {
      .betslip-widget__form-inputs {
        flex-direction: row !important;
      }
    }
  }

  &__content-container {
    @include flex-column(unset, flex-start, 0px);
    flex: 1;
  }

  &__betslip-bets-field {
    flex: 1;
  }

  &__total-amounts-container {
    @include flex(center, space-around, 0px);

    &--horizontal {
      flex-direction: column;

      .betslip-widget__result-total-amounts-element {
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }

  &__result-total-amounts-element {
    width: 100%;
  }

  &__result-total-amounts-element {
    @include flex-column(flex-start, center, 0px);
  }

  &__result-warning-message,
  &__result-success-message {
    @include flex(center, flex-start, 0px);
  }

  &__result-success-message {
    justify-content: space-between;
  }

  &__result-buttons-container {
    @include flex-column(center, flex-start, 0px);
  }

  &__result-buttons-container-field {
    display: flex;
  }

  &__betslip-cards-container {
    flex: 1;
    @include flex-column(unset, space-between, 0px);

    &--single-bets-view {
      justify-content: flex-start;
    }
  }

  &__result-button-field {
    width: 50%;
  }

  &__betslip-loader-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  &__loader {
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    transform: rotate(45deg);
  }
}
</style>
