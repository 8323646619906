<template>
  <div>
    <!--
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        class="cards-list-widget-settings"
        @close="resetStates"
        @blur-tab="resetStates"
        @change="resetStates"
      >
        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #widgetSettings-data>
          <WidgetDynamicParams
            :widget="widget"
            :initial-value="bindingParams"
          />
        </template>

        <template #image>
          <ControlsList
            :widget="widget"
            :controls="imageControls"
          />
        </template>

        <template #title>
          <ControlsList
            :widget="widget"
            :controls="titleControls"
          />
        </template>

        <template #description_1>
          <ControlsList
            :widget="widget"
            :controls="descriptionControls"
          />
        </template>

        <template #buttons_group-design>
          <ControlsList
            :widget="widget"
            :controls="buttonsGroupDesignControls"
          />
        </template>

        <template #buttons_group-content>
          <ControlsList
            :widget="widget"
            :controls="buttonsGroupContentControls"
          />
        </template>

        <template #button_1-design>
          <ControlsList
            :widget="widget"
            :controls="buttonDesignControls"
          />
        </template>

        <template #button_1-content>
          <ControlsList
            :widget="widget"
            :controls="buttonContentControls"
          />
        </template>

        <template #button_1-states>
          <ControlsList
            :widget="widget"
            :controls="buttonStatesControls"
          />
        </template>

        <template #button_2-design>
          <ControlsList
            :widget="widget"
            :controls="buttonDesignControls"
          />
        </template>

        <template #button_2-content>
          <ControlsList
            :widget="widget"
            :controls="buttonContentControls"
          />
        </template>

        <template #button_2-states>
          <ControlsList
            :widget="widget"
            :controls="buttonStatesControls"
          />
        </template>

        <template #card-design>
          <ControlsList
            :widget="widget"
            :controls="cardSlideDesignControls"
          />
        </template>

        <template #card-content>
          <ControlsList
            :widget="widget"
            :controls="cardSlideContentControls"
          />
        </template>

        <template #card-states>
          <ControlsList
            :widget="widget"
            :controls="cardSlideStatesControls"
          />
        </template>

        <template #arrows-design>
          <ControlsList
            :widget="widget"
            :controls="arrowsDesignControls"
          />
        </template>

        <template #arrows-content>
          <ControlsList
            :widget="widget"
            :controls="arrowsContentControls"
          />
        </template>

        <template #arrows-states>
          <ControlsList
            :widget="widget"
            :controls="arrowsStatesControls"
          />
        </template>

        <template #pagination-design>
          <ControlsList
            :widget="widget"
            :controls="paginationDesignControls"
          />
        </template>

        <template #pagination-content>
          <ControlsList
            :widget="widget"
            :controls="paginationContentControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { State } from "~~/models/widgets/widget-controls.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useConfig } from "~~/composables/widgets/cards-list/useCardsListConfig";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetDynamicParams } from "~~/components/dynamic-params/useWidgetDynamicParams";
import { StateController } from "~~/composables/widgets/common/useStates";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
  stateController: StateController;
}>();

const emit = defineEmits<{
  (e: "update-state", event: { state: string; value: State }): void;
  (e: "reset-states"): void;
}>();

const { bindingParams } = useWidgetDynamicParams(
  {
    "dataSource": {
      label: "Data source",
      description:
        "Possible values: static, casino category, casino main page, sportbook main page. Also you can leave it empty for static mode",
      sources: ["custom"],
      required: false,
    },
    "casino__categoryId": {
      label: "For casino data source",
      description: "Category ID",
      sources: ["pageRoute", "custom"],
      required: false,
    },
    "casino__subcategoryId": {
      label: "For casino data source",
      description: "Subcategory ID",
      sources: ["pageRoute", "custom"],
      required: false,
    },
    "useSeoTitle": {
      label: "Use SEO h1",
      description: "Possible values: yes | no. Empty === no",
      sources: ["custom"],
      required: false,
    },
    "useSeoDescription": {
      label: "Use SEO description",
      description: "Possible values: yes | no. Empty === no",
      sources: ["custom"],
      required: false,
    },
  },
  {
    values: props.widget.options.bindingParams || {},
  }
);

const widgetsStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsStore);

const {
  DROPDOWN_ITEMS,
  TABS,

  widgetDesignControls,
  widgetContentControls,

  imageControls,
  titleControls,
  descriptionControls,

  buttonsGroupDesignControls,
  buttonsGroupContentControls,

  buttonDesignControls,
  buttonContentControls,
  buttonStatesControls,

  cardSlideDesignControls,
  cardSlideContentControls,
  cardSlideStatesControls,

  arrowsDesignControls,
  arrowsContentControls,
  arrowsStatesControls,

  paginationDesignControls,
  paginationContentControls,
} = useConfig(ref(props.widget), props.stateController, emit);

const resetStates = () => {
  emit("reset-states");
};

onBeforeUnmount(() => {
  emit("reset-states");
});
</script>
<style lang="scss">
.cards-list-widget-settings {
  .config-radio-icons {
    &__icon-wrapper {
      padding: 8px;
      flex: 1;
    }

    &__icon {
      background-color: $c-light-blue;
      color: $c-primary-base-hover;
      border-radius: 4px;
      width: 100%;
      justify-content: center;
      font-size: 16px;
      padding: 8px 0;
    }
  }
}
</style>
