<template>
  <div
    v-if="hasDesignPermissions && modelValue"
    class="p-t-16 p-b-16 modal-box-config-width"
  >
    <a-tabs
      v-model:activeKey="activeBreakpoint"
      type="card"
    >
      <a-tab-pane
        v-for="tab in tabsConfig"
        :key="tab.key"
        :tab="tab.title"
      >
        <ConfigDimension
          v-for="(_, propKey) in modelValue[tab.key]"
          :key="`${tab.key}-${propKey}`"
          v-model="modelValue[tab.key][propKey]"
          :label="labelDictionary[propKey] || propKey"
          :is-width="propKey.includes('width')"
        />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script setup lang="ts">
import { usePermissions } from "~~/composables/permissions/usePermissions";
import { Breakpoint } from "~~/models/breakpoints.model";
import ConfigDimension from "~~/components/widgets/settings/configs/common/ConfigDimension.vue";
import type { IPage } from "~~/models/stores/pages-store.model";

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

type ModelValue = Required<IPage>["options"]["modalBoxDimensions"];

const modelValue = defineModel<ModelValue>({
  required: true,
});

const activeBreakpoint = ref(Breakpoint.MOBILE);

const tabsConfig = [
  {
    key: Breakpoint.MOBILE,
    title: Breakpoint.MOBILE,
  },
  {
    key: Breakpoint.TABLET,
    title: Breakpoint.TABLET,
  },
  {
    key: Breakpoint.DESKTOP,
    title: Breakpoint.DESKTOP,
  },
];

const labelDictionary = {
  maxWidth: "Max width",
};
</script>
