<template>
  <div>
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        class="promo-bets-fenix-widget-settings"
        @close="resetStates"
        @blur-tab="resetStates"
        @change="resetStates"
      >
        <!--widgetSettings-->
        <template #widgetSettings-data>
          <WidgetDynamicParams
            :widget="widget"
            :initial-value="bindingParams"
          />
        </template>

        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>
        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <!--arrowsSettings-->
        <template #arrows-design>
          <ControlsList
            :widget="widget"
            :controls="arrowsDesignControls"
          />
        </template>
        <template #arrows-content>
          <ControlsList
            :widget="widget"
            :controls="arrowsContentControls"
          />
        </template>
        <template #arrows-states>
          <ControlsList
            :widget="widget"
            :controls="arrowsStatesControls"
          />
        </template>

        <!--paginationSettings-->
        <template #pagination-design>
          <ControlsList
            :widget="widget"
            :controls="paginationDesignControls"
          />
        </template>
        <template #pagination-content>
          <ControlsList
            :widget="widget"
            :controls="paginationContentControls"
          />
        </template>
        <template #pagination-states>
          <ControlsList
            :widget="widget"
            :controls="paginationStatesControls"
          />
        </template>

        <!--cardsSettings-->
        <template #cards-design>
          <ControlsList
            :widget="widget"
            :controls="cardsDesignControls"
          />
        </template>
        <template #cards-content>
          <ControlsList
            :widget="widget"
            :controls="cardsContentControls"
          />
        </template>
        <template #cards-states>
          <ControlsList
            :widget="widget"
            :controls="cardsStatesControls"
          />
        </template>

        <!--imageSettings-->
        <template #image>
          <ControlsList
            :widget="widget"
            :controls="imageControls"
          />
        </template>

        <!--contentSettings-->
        <template #content-design>
          <ControlsList
            :widget="widget"
            :controls="contentDesignControls"
          />
        </template>
        <template #content-content>
          <ControlsList
            :widget="widget"
            :controls="contentContentControls"
          />
        </template>

        <!--eventInfoSettings-->
        <template #event_info-design>
          <ControlsList
            :widget="widget"
            :controls="eventInfoDesignControls"
          />
        </template>
        <template #event_info-content>
          <ControlsList
            :widget="widget"
            :controls="eventInfoContentControls"
          />
        </template>

        <!--teamPlayerSettings-->
        <template #team_player>
          <ControlsList
            :widget="widget"
            :controls="teamPlayerControls"
          />
        </template>

        <!--dateTimeSettings-->
        <template #date_time-design>
          <ControlsList
            :widget="widget"
            :controls="dateTimeDesignControls"
          />
        </template>
        <template #date_time-content>
          <ControlsList
            :widget="widget"
            :controls="dateTimeContentControls"
          />
        </template>

        <!--marketsSettings-->
        <template #markets-design>
          <ControlsList
            :widget="widget"
            :controls="marketsDesignControls"
          />
        </template>
        <template #markets-content>
          <ControlsList
            :widget="widget"
            :controls="marketsContentControls"
          />
        </template>

        <!--outcomesSettings-->
        <template #outcomes-design>
          <ControlsList
            :widget="widget"
            :controls="outcomesDesignControls"
          />
        </template>
        <template #outcomes-content>
          <ControlsList
            :widget="widget"
            :controls="outcomesContentControls"
          />
        </template>
        <template #outcomes-states>
          <ControlsList
            :widget="widget"
            :controls="outcomesStatesControls"
          />
        </template>

        <!--buttonSettings-->
        <template #button-design>
          <ControlsList
            :widget="widget"
            :controls="buttonDesignControls"
          />
        </template>
        <template #button-content>
          <ControlsList
            :widget="widget"
            :controls="buttonContentControls"
          />
        </template>
        <template #button-states>
          <ControlsList
            :widget="widget"
            :controls="buttonStatesControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { State } from "~~/models/widgets/widget-controls.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetDynamicParams } from "~~/components/dynamic-params/useWidgetDynamicParams";
import { useArrowsConfig } from "~~/entities/carousel/composables/useArrowsConfig";
import { usePaginationConfig } from "~~/entities/carousel/composables/usePaginationConfig";
import { StateController } from "~~/composables/widgets/common/useStates";

import { usePromoBetsFenixWidgetConfig } from "./composables/usePromoBetsFenixWidgetConfig";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
  stateController: StateController;
}>();

const widgetsStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsStore);

const {
  DROPDOWN_ITEMS,
  TABS,

  widgetDesignControls,
  widgetContentControls,

  cardsDesignControls,
  cardsContentControls,
  cardsStatesControls,

  imageControls,

  contentDesignControls,
  contentContentControls,

  eventInfoDesignControls,
  eventInfoContentControls,

  teamPlayerControls,

  dateTimeDesignControls,
  dateTimeContentControls,

  marketsDesignControls,
  marketsContentControls,

  outcomesDesignControls,
  outcomesContentControls,
  outcomesStatesControls,

  buttonDesignControls,
  buttonContentControls,
  buttonStatesControls,
} = usePromoBetsFenixWidgetConfig(ref(props.widget), props.stateController);

const { arrowsDesignControls, arrowsContentControls, arrowsStatesControls } =
  useArrowsConfig(ref(props.widget), props.stateController);

const {
  paginationDesignControls,
  paginationContentControls,
  paginationStatesControls,
} = usePaginationConfig(ref(props.widget), props.stateController);

const resetStates = () => {
  props.stateController.resetStates();
};

const { bindingParams } = useWidgetDynamicParams(
  {
    "betDisplayMode": {
      label: "Bet display mode",
      sources: ["custom"],
      description: "Possible values: Name | Code",
      required: true,
    },
    "eventPageURL": {
      label: "Event page URL",
      sources: ["custom"],
      required: true,
    },
  },
  {
    values: props.widget.options.bindingParams || {},
  }
);

onBeforeUnmount(() => {
  resetStates();
});
</script>
<style lang="scss">
.promo-bets-fenix-widget-settings {
  .config-radio-icons {
    &__icon-wrapper {
      padding: 8px;
      flex: 1;
    }

    &__icon {
      background-color: $c-light-blue;
      color: $c-primary-base-hover;
      border-radius: 4px;
      width: 100%;
      justify-content: center;
      font-size: 16px;
      padding: 8px 0;
    }
  }
}
</style>
