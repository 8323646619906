import type { Ref } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { generateContentSectionName } from "~~/helpers/configs/generate-section-name";
import { extendedSizeElement } from "~~/constants/configs/common/extended-size-config";
import { ContainerMode } from "~~/models/widgets/widget-controls.model";
import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { useArrowsConfig } from "~~/entities/carousel/composables/useArrowsConfig";
import { usePaginationConfig } from "~~/entities/carousel/composables/usePaginationConfig";
import { useCarouselConfig } from "~~/entities/carousel/composables/useCarouselConfig";

import { StateController } from "../common/useStates";

export const useConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, string>>,
  emit: any,
  stateController: StateController
) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  const DEFAULT_DROPDOWN_ITEMS = [
    generateDropdownItem("Arrow settings", "arrows"),
    generateDropdownItem("Pagination settings", "pagination"),
  ];

  const TABS = {
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    arrows: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    pagination: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
  };

  const getWidgetHeight = inject<() => number | null>("getWidgetHeight");
  const getWidgetWidth = inject<() => number | null>("getWidgetWidth");

  const heightSizeControls = computed<ControlProp[]>(() => {
    return extendedSizeElement({
      widget,
      label: "Height",
      key: "height",
      getInitialFixedValue: getWidgetHeight,
      className: "p-t-8",
    });
  });

  const widthSizeControls = computed<ControlProp[]>(() => {
    return extendedSizeElement({
      widget,
      label: "Width",
      key: "width",
      getInitialFixedValue: getWidgetWidth,
    });
  });

  const { carousel } = useCarouselConfig(widget);

  const carouselControls = computed<ControlProp[]>(() => {
    if (widget.value.options.displayMode === ContainerMode.FLEX) {
      return [];
    }

    return [...carousel.value];
  });

  const layoutItems = computed<{ icon: string; value: string }[]>(() => {
    if (widget.value.options.displayMode === ContainerMode.FLEX) {
      return [
        {
          icon: "ant-design:arrow-down-outlined",
          value: "vertical",
        },
        {
          icon: "ant-design:arrow-right-outlined",
          value: "horizontal",
        },
        {
          icon: "ant-design:enter-outlined",
          value: "wrap",
        },
      ];
    }

    return [
      {
        icon: "ant-design:arrow-down-outlined",
        value: "vertical",
      },
      {
        icon: "ant-design:arrow-right-outlined",
        value: "horizontal",
      },
    ];
  });

  const widgetContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateContentSectionName("Size"),
        controls: [...widthSizeControls.value, ...heightSizeControls.value],
      },
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.displayMode",
            valueSource: widget.value,
            className: "group-control-element container-widget__layout-config",
            options: {
              items: [
                {
                  value: ContainerMode.FLEX,
                  label: "Flex",
                },
                {
                  value: ContainerMode.CAROUSEL,
                  label: "Carousel",
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.layout",
            valueSource: widget.value,
            visible: widget.value.options.displayMode === ContainerMode.FLEX,
            className: "group-control-element container-widget__layout-config",
            options: {
              items: layoutItems.value,
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.display.horizontalGap",
            valueSource: widget.value,
            options: {
              label: "Gap",
              showIconPrefix: true,
              showSuffix: true,
              suffix: "px",
            },
          },
          {
            componentPath: "ConfigAlignmentFull",
            valuePath: "options.display.alignment",
            valueSource: widget.value,
            className: "group-control-element--child",
            options: {
              label: "Alignment",
              layout: widget.value.options.display.layout,
            },
          },
        ],
      },
      ...carouselControls.value,
      {
        section: generateContentSectionName("Overflow"),
        visible: widget.value.options.displayMode === "flex",
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.horizontalOverflow",
            valueSource: widget.value,
            options: {
              label: "Horizontal overflow",
              items: [
                {
                  label: "Visible",
                  value: "visible",
                },
                {
                  label: "Scroll",
                  value: "auto",
                },
                {
                  label: "Hidden",
                  value: "hidden",
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.verticalOverflow",
            valueSource: widget.value,
            options: {
              label: "Vertical overflow",
              items: [
                {
                  label: "Visible",
                  value: "visible",
                },
                {
                  label: "Scroll",
                  value: "auto",
                },
                {
                  label: "Hidden",
                  value: "hidden",
                },
              ],
            },
          },
        ],
      },
      {
        section: generateContentSectionName("Scrolling"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.scrolling",
            valueSource: widget.value,
            options: {
              label: "Position when scrolling",
              items: [
                {
                  label: "Not fix",
                  value: "",
                },
                {
                  label: "Fix top",
                  value: "fix_top",
                },
                {
                  label: "Fix bottom",
                  value: "fix_bottom",
                },
              ],
            },
          },
          {
            componentPath: "ConfigVerticalSpace",
            valuePath: "options.display.offset",
            visible:
              widget.value.options.display.scrolling === "fix_top" ||
              widget.value.options.display.scrolling === "fix_bottom",
            valueSource: widget.value,
            options: {
              placeholder: "None",
              label: "Offset",
            },
          },
        ],
      },
    ];
  });

  const widgetDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: widget.value,
    });
  });

  const { arrowsDesignControls, arrowsContentControls, arrowsStatesControls } =
    useArrowsConfig(widget, stateController);

  const { paginationDesignControls, paginationContentControls } =
    usePaginationConfig(widget, stateController);

  return {
    TABS,
    DEFAULT_DROPDOWN_ITEMS,
    fields,

    /*
      Controls
    */
    widgetDesignControls,
    widgetContentControls,

    arrowsDesignControls,
    arrowsContentControls,
    arrowsStatesControls,

    paginationDesignControls,
    paginationContentControls,
  };
};
