<template>
  <div>
    <!-- 
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DEFAULT_DROPDOWN_ITEMS"
        :tabs="TABS"
      >
        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #widgetSettings-data>
          <WidgetDynamicParams
            :widget="widget"
            :initial-value="bindingParams"
          />
        </template>

        <template #pagination-design>
          <ControlsList
            :widget="widget"
            :controls="paginationDesignControls"
          />
        </template>

        <template #pagination-content>
          <ControlsList
            :widget="widget"
            :controls="paginationContentControls"
          />
        </template>

        <template #arrows-design>
          <ControlsList
            :widget="widget"
            :controls="arrowsDesignControls"
          />
        </template>

        <template #arrows-content>
          <ControlsList
            :widget="widget"
            :controls="arrowsContentControls"
          />
        </template>

        <template #arrows-states>
          <ControlsList
            :widget="widget"
            :controls="arrowsStatesControls"
          />
        </template>

        <template #match_cards-design>
          <ControlsList
            :widget="widget"
            :controls="defaultDesignControls"
          />
        </template>

        <template #match_cards-content>
          <ControlsList
            :widget="widget"
            :controls="topMatchContentControls"
          />
        </template>

        <template #match_cards-states>
          <ControlsList
            :widget="widget"
            :controls="topMatchStatesControls"
          />
        </template>

        <template #title>
          <ControlsList
            :widget="widget"
            :controls="titleControls"
          />
        </template>

        <!-- Success -->

        <template #success_message-design>
          <ControlsList
            :widget="widget"
            :controls="defaultDesignControls"
          />
        </template>

        <template #success_message-content>
          <ControlsList
            :widget="widget"
            :controls="successContentControls"
          />
        </template>

        <template #bet_card-design>
          <ControlsList
            :widget="widget"
            :controls="defaultDesignControls"
          />
        </template>

        <template #bet_card-content>
          <ControlsList
            :widget="widget"
            :controls="betCardContentControls"
          />
        </template>

        <template #bets_type-design>
          <ControlsList
            :widget="widget"
            :controls="defaultDesignControls"
          />
        </template>

        <template #bets_type-content>
          <ControlsList
            :widget="widget"
            :controls="betsTypeContentControls"
          />
        </template>

        <template #event_container-design>
          <ControlsList
            :widget="widget"
            :controls="defaultDesignControls"
          />
        </template>

        <template #event_container-content>
          <ControlsList
            :widget="widget"
            :controls="eventContainerContentControls"
          />
        </template>

        <template #bet_name>
          <ControlsList
            :widget="widget"
            :controls="textWithPaddingControls"
          />
        </template>

        <template #coefficent-design>
          <ControlsList
            :widget="widget"
            :controls="defaultDesignControls"
          />
        </template>

        <template #coefficent-content>
          <ControlsList
            :widget="widget"
            :controls="coefContentControls"
          />
        </template>

        <template #result_bet_amounts>
          <ControlsList
            :widget="widget"
            :controls="betAmountsControls"
          />
        </template>

        <template #continue_button-design>
          <ControlsList
            :widget="widget"
            :controls="continueButtonDesignControls"
          />
        </template>

        <template #continue_button-content>
          <ControlsList
            :widget="widget"
            :controls="continueButtonContentControls"
          />
        </template>

        <template #continue_button-states>
          <ControlsList
            :widget="widget"
            :controls="continueButtonStatesControls"
          />
        </template>

        <template #image>
          <ControlsList
            :widget="widget"
            :controls="flexibleImageControls"
          />
        </template>

        <template #content-design>
          <ControlsList
            :widget="widget"
            :controls="defaultDesignControls"
          />
        </template>

        <template #content-content>
          <ControlsList
            :widget="widget"
            :controls="contentContentControls"
          />
        </template>

        <template #timer-design>
          <ControlsList
            :widget="widget"
            :controls="defaultDesignControls"
          />
        </template>

        <template #timer-content>
          <ControlsList
            :widget="widget"
            :controls="timerContentControls"
          />
        </template>

        <template #timer_header-design>
          <ControlsList
            :widget="widget"
            :controls="defaultDesignControls"
          />
        </template>

        <template #timer_header-content>
          <ControlsList
            :widget="widget"
            :controls="timerHeaderControls"
          />
        </template>

        <template #timer_counter-design>
          <ControlsList
            :widget="widget"
            :controls="defaultDesignControls"
          />
        </template>

        <template #timer_counter-content>
          <ControlsList
            :widget="widget"
            :controls="timerCounterContentControls"
          />
        </template>

        <template #timer_value-design>
          <ControlsList
            :widget="widget"
            :controls="defaultDesignControls"
          />
        </template>

        <template #timer_value-content>
          <ControlsList
            :widget="widget"
            :controls="timerValueControls"
          />
        </template>

        <template #timer_separator-design>
          <ControlsList
            :widget="widget"
            :controls="defaultDesignControls"
          />
        </template>

        <template #timer_separator-content>
          <ControlsList
            :widget="widget"
            :controls="timerSeparatorControls"
          />
        </template>

        <template #event_info-design>
          <ControlsList
            :widget="widget"
            :controls="defaultDesignControls"
          />
        </template>

        <template #event_info-content>
          <ControlsList
            :widget="widget"
            :controls="eventInfoContentControls"
          />
        </template>

        <template #team>
          <ControlsList
            :widget="widget"
            :controls="teamControls"
          />
        </template>

        <template #date>
          <ControlsList
            :widget="widget"
            :controls="dateControls"
          />
        </template>

        <template #markets-design>
          <ControlsList
            :widget="widget"
            :controls="defaultDesignControls"
          />
        </template>

        <template #markets-content>
          <ControlsList
            :widget="widget"
            :controls="marketsContentControls"
          />
        </template>

        <template #outcomes-design>
          <ControlsList
            :widget="widget"
            :controls="betItemsDesignControls"
          />
        </template>

        <template #outcomes-content>
          <ControlsList
            :widget="widget"
            :controls="betItemsContentControls"
          />
        </template>

        <template #outcomes-states>
          <ControlsList
            :widget="widget"
            :controls="betItemsStateControls"
          />
        </template>

        <template #bets-design>
          <ControlsList
            :widget="widget"
            :controls="defaultDesignControls"
          />
        </template>

        <template #bets-content>
          <ControlsList
            :widget="widget"
            :controls="betsContentControls"
          />
        </template>

        <template #bet_amounts-design>
          <ControlsList
            :widget="widget"
            :controls="defaultDesignControls"
          />
        </template>

        <template #bet_amounts-content>
          <ControlsList
            :widget="widget"
            :controls="betsAmountContentControls"
          />
        </template>

        <template #bet_amounts-states>
          <ControlsList
            :widget="widget"
            :controls="betAmountsStatesControls"
          />
        </template>

        <template #input-design>
          <ControlsList
            :widget="widget"
            :controls="formFieldDesignStyles"
          />
        </template>

        <template #input-content>
          <ControlsList
            :widget="widget"
            :controls="formFieldContentStyles"
          />
        </template>

        <template #input-states>
          <ControlsList
            :widget="widget"
            :controls="formFieldStates"
          />
        </template>

        <template #not_enough_container-design>
          <ControlsList
            :widget="widget"
            :controls="defaultDesignControls"
          />
        </template>

        <template #not_enough_container-content>
          <ControlsList
            :widget="widget"
            :controls="notEnoughFundsContentControls"
          />
        </template>

        <template #button_group-design>
          <ControlsList
            :widget="widget"
            :controls="defaultDesignControls"
          />
        </template>

        <template #button_group-content>
          <ControlsList
            :widget="widget"
            :controls="buttonsGroupContentControls"
          />
        </template>

        <template #button_1-design>
          <ControlsList
            :widget="widget"
            :controls="placeButtonDesignControls"
          />
        </template>

        <template #button_1-content>
          <ControlsList
            :widget="widget"
            :controls="placeButtonContentControls"
          />
        </template>

        <template #button_1-states>
          <ControlsList
            :widget="widget"
            :controls="placeButtonStatesControls"
          />
        </template>

        <template #button_2-design>
          <ControlsList
            :widget="widget"
            :controls="seeMoreBetsButtonDesignControls"
          />
        </template>

        <template #button_2-content>
          <ControlsList
            :widget="widget"
            :controls="seeMoreBetsButtonContentControls"
          />
        </template>

        <template #button_2-states>
          <ControlsList
            :widget="widget"
            :controls="seeMoreBetsButtonStatesControls"
          />
        </template>

        <template #event_name>
          <ControlsList
            :widget="widget"
            :controls="textWithPaddingControls"
          />
        </template>

        <template #bet_market>
          <ControlsList
            :widget="widget"
            :controls="textWithPaddingControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useConfig } from "~~/composables/widgets/top-match-fenix/useTopMatchFenixConfig";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { State } from "~~/models/widgets/widget-controls.model";
import { useWidgetDynamicParams } from "~~/components/dynamic-params/useWidgetDynamicParams";
import type { StateController } from "~~/composables/widgets/common/useStates";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, string>;
  stateController: StateController;
}>();

const emit = defineEmits<{
  (e: "update-state", event: { state: string; value: State }): void;
  (e: "reset-states"): void;
}>();

const widgetsStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsStore);

const { bindingParams } = useWidgetDynamicParams(
  {
    "eventPageURL": {
      label: "Event page URL",
      sources: ["custom"],
      required: false,
    },
    "depositPageURL": {
      label: "Deposit page URL",
      sources: ["custom"],
      required: false,
    },
  },
  {
    values: props.widget.options.bindingParams || {},
  }
);

const {
  TABS,
  DEFAULT_DROPDOWN_ITEMS,

  widgetDesignControls,
  widgetContentControls,

  arrowsDesignControls,
  arrowsContentControls,
  arrowsStatesControls,
  paginationDesignControls,
  paginationContentControls,

  defaultDesignControls,
  titleControls,
  topMatchContentControls,
  topMatchStatesControls,
  flexibleImageControls,
  contentContentControls,
  timerContentControls,
  timerHeaderControls,
  timerCounterContentControls,
  timerSeparatorControls,
  eventInfoContentControls,
  teamControls,
  dateControls,
  marketsContentControls,
  betItemsDesignControls,
  betItemsContentControls,
  betItemsStateControls,
  betsContentControls,
  betsAmountContentControls,
  betAmountsStatesControls,
  formFieldDesignStyles,
  formFieldContentStyles,
  formFieldStates,
  notEnoughFundsContentControls,
  buttonsGroupContentControls,

  placeButtonDesignControls,
  placeButtonContentControls,
  placeButtonStatesControls,

  seeMoreBetsButtonDesignControls,
  seeMoreBetsButtonContentControls,
  seeMoreBetsButtonStatesControls,

  /* 
    Success
  */
  successContentControls,
  betCardContentControls,
  betsTypeContentControls,
  eventContainerContentControls,
  textWithPaddingControls,
  coefContentControls,
  betAmountsControls,
  continueButtonDesignControls,
  continueButtonContentControls,
  continueButtonStatesControls,
  timerValueControls,
} = useConfig(
  ref(props.widget),
  toRef(props.states),
  emit,
  props.stateController
);
</script>

<style lang="scss"></style>
