import {
  Align,
  ContainerMode,
  Grid,
  ResizingType,
  SliderTransition,
  State,
  TextStyle,
} from "~~/models/widgets/widget-controls.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { TITLE_INITIAL } from "~~/constants/configs/common/title-initial";
import { useWidgetsStore } from "~~/store/widgets";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";
import {
  DESIGN_INITIAL,
  getDefaultAlignValue,
  getDefaultBorderValue,
  getDefaultDecoration,
  getDefaultFillImage,
  getDefaultFillValue,
  getDefaultShadow,
  getDefaultSizeValue,
  getDefaultSpacing,
  getDefaultTheme,
} from "~~/constants/configs/common/widget-initial";
import { FLEXIBLE_IMAGE_INITIAL } from "~~/constants/configs/common/flexible-image-initial";
import {
  BUTTON_INITIAL,
  BUTTONS_GROUP_INITIAL,
} from "~~/constants/configs/card-common/initial-constants";
import { FieldName } from "~~/models/widgets/widget.core/field-names.enum";
import { WIDGET_FIELD_TYPES } from "~~/models/common/field-types.enum";
import { getInitialOptions } from "~~/assets/utils/common/init-data";
import { useArrowsInit } from "~~/entities/carousel/composables/useArrowsInit";
import { usePaginationInit } from "~~/entities/carousel/composables/usePaginationInit";

import { DESCRIPTION_INITIAL } from "../text/useTextWidgetConfig";

export const useInit = (widget: Ref<IWidgetWithFields>) => {
  const widgetsStore = useWidgetsStore();

  const DEFAULT_STATES = {
    card: State.DEFAULT,
    title: State.DEFAULT,
    button_1: State.DEFAULT,
    button_2: State.DEFAULT,
    arrows: State.DEFAULT,
  };

  const states = ref<Record<string, State>>({
    ...DEFAULT_STATES,
  });

  const fields = useWidgetFields(widget.value);

  const {
    addFieldInitialOptions,

    initFields,
    initWidgetOptions,
    generateDummyField,
  } = useCreateFields(widget.value);

  const widgetOptions = initWidgetOptions(
    [],
    {
      display: {
        mode: ContainerMode.GRID,
        width: 228,
        height: getDefaultSizeValue(ResizingType.HUG),
        layout: Grid.FILL,
        alignment: getDefaultAlignValue(),
        gap: {
          row: 16,
          column: 16,
        },
        grid: {
          gridFixedType: Grid.FIT,
          maxCardsInRow: 3,
          minCardsInRow: 1,
        },
        slider: {
          visible: 3,
          transition: SliderTransition.ALL,
          infinite: false,
          auto: false,
          delay: 7,
        },
      },
    },
    {
      fill: getDefaultFillValue("#ffffff"),
      spacing: {
        padding: getDefaultSpacing(16),
        margin: getDefaultSpacing(),
      },
    }
  );

  const init = (): void => {
    initFields();

    addFieldInitialOptions("image", {
      data: FLEXIBLE_IMAGE_INITIAL(),
      values: {
        resizing: {
          type: ResizingType.FILL,
          position: "center center",
          x: 50,
          y: 50,
        },
      },
    });

    addFieldInitialOptions(
      "title",
      {
        data: TITLE_INITIAL(),
        values: {
          theme: getDefaultTheme(16),
          decoration: getDefaultDecoration([TextStyle.BOLD]),
          padding: getDefaultSpacing({
            left: 16,
            right: 16,
          }),
        },
      },
      { value: "Title" }
    );

    addFieldInitialOptions(
      "description_1",
      {
        data: DESCRIPTION_INITIAL(),
        values: {
          padding: getDefaultSpacing({
            left: 16,
            right: 16,
          }),
        },
        additionalData: {
          options: {
            states: {
              [State.HOVER]: {
                color: getDefaultFillValue("#000000"),
              },
            },
          },
        },
      },
      { value: "Description" }
    );

    addFieldInitialOptions(
      "buttons_group",
      {
        data: BUTTONS_GROUP_INITIAL(),
        exclude: ["buttonDisplaySettings"],
        values: {
          padding: getDefaultSpacing({
            top: 8,
            bottom: 16,
            left: 16,
            right: 16,
          }),
        },
      },
      { value: "Description" }
    );

    addFieldInitialOptions(
      "button_1",
      {
        data: BUTTON_INITIAL(),
      },
      { value: "Button" }
    );

    addFieldInitialOptions(
      "button_2",
      {
        data: BUTTON_INITIAL(),
      },
      { value: "Button" }
    );

    addFieldInitialOptions("arrows", useArrowsInit());
    addFieldInitialOptions("pagination", usePaginationInit());

    const initialCardFields = computed(() => [
      fields.value.title.field,
      fields.value.image.field,
      fields.value.description_1.field,
      fields.value.buttons_group.field,
      fields.value.button_1.field,
      fields.value.button_2.field,
    ]);

    const initialCardSlideState = () => {
      return {
        fillImageColor: getDefaultFillImage({
          value: getDefaultFillValue("#F5F5F5"),
        }),
        border: getDefaultBorderValue(),
        shadow: getDefaultShadow(),
      };
    };

    const cardSlideInitialOptions = getInitialOptions({
      data: DESIGN_INITIAL(),
      exclude: ["fill", "spacing"],
      additionalData: {
        options: {
          display: {
            layout: {
              position: "bottom",
              alignment: null,
            },
            gap: 16,
            alignment: getDefaultAlignValue(),
            alignSelf: Align.START,
          },
          link: {
            type: null,
            value: null,
            authorizeValue: null,
            hasAuthorizeValue: false,
            isNewTabOpen: null,
          },
          padding: getDefaultSpacing(),
          fillImageColor: getDefaultFillImage({
            value: getDefaultFillValue("#F5F5F5"),
            _useLazyLoad: false,
            _isImagePreload: false,
          }),
          states: {
            [State.HOVER]: initialCardSlideState(),
          },
        },
      },
    }).options;

    addFieldInitialOptions(
      "items",
      {
        data: {
          options: {},
        },
      },
      {
        type: WIDGET_FIELD_TYPES.ARRAY_ELEMENT,
        value: [
          {
            ...generateDummyField({
              name: FieldName.CARD,
              title: "Card",
              value: "Card",
              options: cardSlideInitialOptions,
            }),
            fields: initialCardFields.value,
          },
        ],
      }
    );

    widgetsStore.updateWidgetOptions(widget.value, widgetOptions);
  };

  return {
    fields,
    states,
    DEFAULT_STATES,

    init,
  };
};
