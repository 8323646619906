import { type Ref } from "vue";

import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import {
  DisplayOrientation,
  State,
} from "~~/models/widgets/widget-controls.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { typography } from "~~/constants/configs/text-common/typography-config";
import {
  generateContentSectionName,
  generateDesignSectionName,
} from "~~/helpers/configs/generate-section-name";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";

import { useWidgetFields } from "../useWidgetFields";
import { useButtonControls } from "../card/useButtonControls";

export const useBetslipBetsContainerControls = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any,
  statesValuePath: ComputedRef<string>,
  items: Record<string, { label: string; value: string }[]>,
  isFenix?: boolean
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedField } = storeToRefs(widgetSettingsStore);
  const fields = useWidgetFields(widget.value);

  const descriptionFields = computed(() => {
    return widget.value.fields.filter(field =>
      field.name.includes("no_bets_description")
    );
  });

  const betsDesignControls = computed(() => {
    if (isFenix) {
      return [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: "options.fill",
          valueSource: selectedField.value,
          options: {
            placeholder: "None",
            label: "Fill",
            isBold: true,
          },
        },
        {
          componentPath: "ConfigBorder",
          valuePath: "options.border",
          valueSource: selectedField.value,
          options: {
            placeholder: "None",
            label: "Border",
            isBold: true,
          },
        },
        {
          componentPath: "ConfigBoxShadow",
          valuePath: "options.shadow",
          valueSource: selectedField.value,
          options: {
            placeholder: "None",
            label: "Shadow",
            isBold: true,
          },
        },
        {
          componentPath: "ConfigCornerRadius",
          valuePath: "options.cornerRadius",
          valueSource: selectedField.value,
          options: {
            placeholder: "None",
            label: "Corner radius",
            isBold: true,
          },
        },
        {
          componentPath: "ConfigPaddingInputs",
          valuePath: "options.padding",
          valueSource: selectedField.value,
          options: {
            label: "Padding",
            meta: {
              isBold: true,
            },
          },
        },
      ];
    }

    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigColumnWidth",
        valuePath: "options.spaceBetween",
        valueSource: selectedField.value,
        options: {
          label: "Distance between cards",
          iconName: "ant-design:column-height-outlined",
          showIconPrefix: false,
          isBold: true,
          showSuffix: true,
          suffix: "px",
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const noBetsStatesTabs = computed(() => ({
    componentPath: "common/ConfigStatesList",
    valuePath: "",
    valueSource: states.value.bets,
    options: {
      items: [
        {
          label: STATE_KEY_LABELS[State.ACTIVE_BETS],
          value: State.ACTIVE_BETS,
        },
        {
          label: STATE_KEY_LABELS[State.INACTIVE_BETS],
          value: State.INACTIVE_BETS,
        },
      ],
    },
    onUpdate(value: State) {
      emit("update-state", {
        state: "bets",
        value,
      });
    },
  }));

  const currentBetsContainerItems = computed<
    Array<{ label: string; value: string; isActiveValueSource?: any }>
  >(() => {
    if (states.value.bets === State.INACTIVE_BETS) {
      switch (states.value[widget.value.name]) {
        case State.MY_BETS:
          return items.NOBETS_SETTINGS_MYBETS_ITEMS;
        default:
          return items.NOBETS_SETTINGS_BETSLIP_ITEMS;
      }
    }

    if (states.value[widget.value.name] === State.BETSLIP) {
      return items.BETS_SETTINGS_BETSLIP_ITEMS;
    }

    if (states.value[widget.value.name] === State.BOOKING_CODE) {
      return items.BETS_SETTINGS_BOOKING_CODE_ITEMS;
    }

    if (states.value[widget.value.name] === State.BETS_RESULT) {
      return items.BETS_SETTINGS_BETS_RESULT_ITEMS;
    }

    return items.BETS_SETTINGS_DEFAULT_ITEMS;
  });

  const betsContentControls = computed(() => {
    if (states.value[widget.value.name] === State.NO_BETS) {
      return [
        {
          componentPath: "content/ConfigEditorWithToggle",
          valuePath: "value",
          valueSource: fields.value.no_bets_description.field,
          options: {
            isMultiple: false,
            placeholder: "Enter text",
            label: "Description",
            allowDisable: true,
            fields: descriptionFields.value,
            primaryField: fields.value.no_bets_description.field,
            active: fields.value.no_bets_description.active,
            showOptions: fields.value.no_bets_description.active,
            "onUpdate:active": (value: boolean) => {
              fields.value.no_bets_description.field.options._active = value;
            },
            "onShow-options": () => {
              widgetSettingsStore.setActiveElement(
                widget.value,
                fields.value.no_bets_description.field
              );
            },
          },
        },
      ];
    }
    return [
      isFenix &&
      [State.BETSLIP, State.MY_BETS].includes(states.value[widget.value.name])
        ? noBetsStatesTabs.value
        : {},
      {
        section: generateContentSectionName("Display mode"),
        visible: states.value.bets !== State.INACTIVE_BETS,
        controls: [
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.distance",
            valueSource: selectedField.value,
            className: "group-control-element",
            options: {
              label: "Distance between elements",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: currentBetsContainerItems.value,
        },
      },
    ];
  });

  const betsPreviewControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value[selectedField.value!.name],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.single_bets,
              value: State.SINGLE_BETS,
            },
            {
              label: STATE_KEY_LABELS.multi_bets,
              value: State.MULTI_BETS,
            },
            {
              label: STATE_KEY_LABELS.system_bets,
              value: State.SYSTEM_BETS,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: selectedField.value!.name, value });
        },
      },
    ];
  });

  const betsCardsDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
      {
        componentPath: "ConfigMarginInputs",
        valuePath: "options.margins",
        valueSource: selectedField.value,
        options: {
          label: "Margin",
          meta: {
            isBold: true,
          },
          isAllSideSetting: true,
          showTooltip: true,
        },
      },
    ];
  });

  const currentCardsSettingsItems = computed<
    Array<{ label: string; value: string }>
  >(() => {
    if (states.value[widget.value.name] === State.MY_BETS) {
      return items.BETS_CARDS_MY_BETS_SETTINGS_ITEMS;
    }

    if (states.value[widget.value.name] === State.BETS_RESULT) {
      return items.BETS_CARDS_BETS_RESULT_SETTINGS_ITEMS;
    }

    return items.BETS_CARDS_DEFAULT_SETTINGS_ITEMS;
  });

  const betsCardsContentControls = computed(() => {
    if (isFenix) {
      return [
        {
          section: generateContentSectionName("Display settings"),
          controls: [
            {
              componentPath: "ConfigColumnWidth",
              valuePath: "options.display.distance",
              valueSource: selectedField.value,
              className: "group-control-element",
              options: {
                label: "Distance between elements",
                showIconPrefix: false,
                showSuffix: true,
                suffix: "px",
              },
            },
          ],
        },
        {
          componentPath: "form/ConfigTabs",
          options: {
            items: currentCardsSettingsItems.value,
          },
        },
      ];
    }

    return [
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: currentCardsSettingsItems.value,
        },
      },
    ];
  });

  const liveStatusDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
      {
        componentPath: "ConfigMarginInputs",
        valuePath: "options.margins",
        valueSource: selectedField.value,
        options: {
          label: "Margin",
          meta: {
            isBold: true,
          },
          isAllSideSetting: true,
        },
      },
    ];
  });

  const liveStatusContentControls = computed(() => {
    return [
      typography({ source: selectedField.value, except: ["ConfigAlignment"] }),
    ];
  });

  const notAviableStatusDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
      {
        componentPath: "ConfigMarginInputs",
        valuePath: "options.margins",
        valueSource: selectedField.value,
        options: {
          label: "Margin",
          meta: {
            isBold: true,
          },
          isAllSideSetting: true,
        },
      },
    ];
  });

  const notAviableStatusContentControls = computed(() => {
    return [
      typography({ source: selectedField.value, except: ["ConfigAlignment"] }),
    ];
  });

  const betsTypeDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
      {
        componentPath: "ConfigMarginInputs",
        valuePath: "options.margins",
        valueSource: selectedField.value,
        options: {
          label: "Margin",
          meta: {
            isBold: true,
          },
          isAllSideSetting: true,
        },
      },
    ];
  });

  const betsTypeContentControls = computed(() => {
    return [
      typography({ source: selectedField.value, except: ["ConfigAlignment"] }),
    ];
  });

  const betsTypeStatesControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value[selectedField.value!.name],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.single_bets,
              value: State.SINGLE_BETS,
            },
            {
              label: STATE_KEY_LABELS.multi_bets,
              value: State.MULTI_BETS,
            },
            {
              label: STATE_KEY_LABELS.system_bets,
              value: State.SYSTEM_BETS,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: selectedField.value!.name, value });
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${statesValuePath.value}.fill`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${statesValuePath.value}.border`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${statesValuePath.value}.color`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Text color",
          isBold: true,
        },
      },
    ];
  });

  const timeControls = computed(() => {
    return [
      typography({ source: selectedField.value, except: ["ConfigAlignment"] }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const closeIconControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Styles"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.color",
            valueSource: selectedField.value,
            options: {
              placeholder: "None",
              label: "Icon color",
              isBold: false,
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.iconSize",
            className: "p-l-16 p-r-16 p-t-16",
            valueSource: selectedField.value,
            options: {
              label: "Size",
              minValue: 12,
              maxValue: 48,
            },
          },
        ],
      },
    ];
  });

  const eventContainerDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
      {
        componentPath: "ConfigMarginInputs",
        valuePath: "options.margins",
        valueSource: selectedField.value,
        options: {
          label: "Margin",
          meta: {
            isBold: true,
          },
          isAllSideSetting: true,
          showTooltip: true,
        },
      },
    ];
  });

  const eventContainerContentControls = computed(() => {
    if (isFenix) {
      return [
        {
          section: generateContentSectionName("Display mode"),
          controls: [
            {
              componentPath: "ConfigColumnWidth",
              valuePath: "options.display.distance",
              valueSource: selectedField.value,
              className: "group-control-element",
              options: {
                label: "Distance between elements",
                showIconPrefix: false,
                showSuffix: true,
                suffix: "px",
              },
            },
          ],
        },
        {
          componentPath: "form/ConfigTabs",
          options: {
            items: items.EVENT_CONTAINER_ITEMS,
          },
        },
      ];
    }

    return [
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: items.EVENT_CONTAINER_ITEMS,
        },
      },
    ];
  });

  const eventNameControls = computed(() => {
    return [
      typography({ source: selectedField.value, except: ["ConfigAlignment"] }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const betMarketControls = computed(() => {
    return [
      typography({ source: selectedField.value, except: ["ConfigAlignment"] }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const betNameControls = computed(() => {
    return [
      typography({ source: selectedField.value, except: ["ConfigAlignment"] }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const coefficientDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
      {
        componentPath: "ConfigMarginInputs",
        valuePath: "options.margins",
        valueSource: selectedField.value,
        options: {
          label: "Margin",
          meta: {
            isBold: true,
          },
          isAllSideSetting: true,
        },
      },
    ];
  });

  const coefficientContentControls = computed(() => {
    return [
      typography({ source: selectedField.value, except: ["ConfigAlignment"] }),
    ];
  });

  const coefficientStatesControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value[selectedField.value!.name],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.up,
              value: State.UP,
            },
            {
              label: STATE_KEY_LABELS.down,
              value: State.DOWN,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: selectedField.value!.name, value });
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${statesValuePath.value}.fill`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${statesValuePath.value}.border`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${statesValuePath.value}.color`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Text color",
          isBold: true,
          type: ColorPickerType.TEXT,
        },
      },
      {
        section: generateDesignSectionName("Arrow style"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${statesValuePath.value}.arrow.color`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              type: ColorPickerType.TEXT,
              label: "Arrow color",
            },
          },
        ],
      },
    ];
  });

  const bookingLabelControls = computed(() => {
    return [typography({ source: selectedField.value })];
  });

  const {
    buttonStatesControls: bookingButtonStatesControls,
    buttonDesignControls: bookingButtonDesignControls,
    buttonContentControls,
  } = useButtonControls({
    state: toRef(() => states.value[selectedField.value!.name]),
    stateHandler: (state: State) => {
      states.value[selectedField.value!.name] = state;
    },
    exclude: {
      content: ["ConfigIconSettings", "content/ConfigTextInputWithToggle"],
    },
    valueSource: toRef(selectedField.value!),
    showAlignmentOnFillOnly: false,
  });

  const bookingButtonContentControls = computed(() => {
    const [display, ...settings] = buttonContentControls.value;
    return [
      display,
      {
        section: generateContentSectionName("Typography"),
        controls: settings,
      },
    ];
  });

  /* 
    Booking error
  */
  const bookingErrorDesignControls = computed(() => {
    return getInitialDesignList({
      source: selectedField.value,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: selectedField.value,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
        [
          5,
          {
            componentPath: "ConfigMarginInputs",
            valuePath: "options.margins",
            valueSource: selectedField.value,
            options: {
              label: "Margin",
              showTooltip: true,
              isAllSideSetting: true,
              meta: {
                isBold: true,
              },
            },
          },
        ],
      ],
    });
  });

  const bookingErrorContentControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.layout",
            valueSource: selectedField.value,
            options: {
              label: "Layout",
              items: [
                {
                  label: "Horizontal",
                  value: "horizontal",
                },
                {
                  label: "Vertical",
                  value: "vertical",
                },
              ],
            },
          },
          selectedField.value?.options.layout === DisplayOrientation.VERTICAL
            ? {
                componentPath: "ConfigAlignment",
                valuePath: "options.horizontalAlignment",
                valueSource: selectedField.value,
                className: "group-control-element--child",
                options: {
                  label: "Alignment",
                  isTextContentIcons: false,
                },
              }
            : {
                componentPath: "ConfigVerticalAlignment",
                valuePath: "options.verticalAlignment",
                valueSource: selectedField.value,
                className: "group-control-element--child",
                options: {
                  label: "Alignment",
                },
              },

          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.distance",
            valueSource: selectedField.value,
            className: "group-control-element",
            options: {
              label: "Distance between elements",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      typography(
        {
          source: selectedField.value,
          except: ["ConfigAlignment"],
        },
        "Text"
      ),
      {
        componentPath: "ConfigIconSettings",
        valuePath: "options.icon",
        valueSource: selectedField.value,
        options: {
          label: "Icon",
          allowDisable: true,
          isStatesTab: false,
        },
      },
    ];
  });

  return {
    betsDesignControls,
    betsContentControls,
    betsPreviewControls,

    betsCardsDesignControls,
    betsCardsContentControls,

    liveStatusDesignControls,
    liveStatusContentControls,

    notAviableStatusDesignControls,
    notAviableStatusContentControls,

    closeIconControls,

    eventNameControls,

    betMarketControls,

    betNameControls,

    coefficientDesignControls,
    coefficientContentControls,
    coefficientStatesControls,

    betsTypeDesignControls,
    betsTypeContentControls,
    betsTypeStatesControls,

    timeControls,

    eventContainerDesignControls,
    eventContainerContentControls,

    bookingLabelControls,

    bookingButtonStatesControls,
    bookingButtonDesignControls,
    bookingButtonContentControls,

    bookingErrorDesignControls,
    bookingErrorContentControls,
  };
};
