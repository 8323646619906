import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";
import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import {
  Alignment,
  DisplayOrientation,
  ResizingType,
} from "~~/models/widgets/widget-controls.model";
import { getEventInfoVariantStyles } from "~~/components/widgets/widgets-entities/PromoBetsFenixWidget/PromoBetsFenixWidgetHelpers";
import {
  EventInfoDirection,
  EventInfoVariant,
} from "~~/components/widgets/widgets-entities/PromoBetsFenixWidget/PromoBetsFenixWidgetTypes";
import { ElementStyle } from "~~/models/common";
import {
  generateArrows,
  generatePagination,
} from "~~/components/widgets/widgets-entities/PromoBetsFenixWidget/promo-bets-fenix-widget-css";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateCustomStyles,
  generateStringDefault,
  generateStringWithStates,
} from "../utils/pipe-helper-functions";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
} from "../compiler/default-css-compiler";
import {
  generateFlex,
  generateFontSize,
  generateTextColor,
  generateWidgetHeight,
} from "../helpers";
import { prefillWithClasses } from "../utils/prefill-with-classes";
import { getFlexPosition } from "../../widget-settings";
import {
  generateFieldsWithStatesCssString,
  generateFlexibleImageCssString,
  generateIconCssString,
  generateIconSizeCssString,
} from "../utils/form-helper-functions";
import { getPxValueFromNumber, styleObjectToStringAdvanced } from "../..";

import { generateBetItemsCssString } from "./betslist-widget-css";
import { generateButtonCssString } from "./card-widget-css";

// Generating styles here, since button and image has custom fields

const generateTitleCssString =
  (field: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = field;

    const flex = generateFlex({
      flex: "flex",
      justify: getFlexPosition(options.display.alignment),
      align: "center",
      gap: options.icon.gap,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: options,
      customFunction: generateDefaultStyles,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: flex,
    });

    const iconPosition = options.icon.position;

    if (iconPosition === Alignment.RIGHT) {
      cssString += generateCssClassWithContent({
        className: options._cssClass,
        pseudoClassName: ` .${options.icon._cssClass}`,
        content: "order:1;",
      });
    }

    return cssString;
  };

const generateContentStyles =
  (field: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = field;

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: options,
      customFunction: generateDefaultStyles,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: generateFlex({
        flex: "flex",
        direction: "column",
        gap: options.display.gap,
        align: options.display.align.alignItems,
        justify: options.display.align.justifyContent,
      }),
    });

    return cssString;
  };

const generateFlexDisplayStyles =
  (field: IWidgetOptions, dir = "column", customProps = { flex: {} }) =>
  (cssString: string): string => {
    const { options } = field;

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: options,
      customFunction: generateDefaultStyles,
    });

    const display =
      options.display.layout === ResizingType.FILL ? "flex" : "inline-flex";

    const align = getFlexPosition(options.display.alignment);
    const width =
      options.display.layout === ResizingType.FILL ? "width:100%;" : "";

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content:
        generateFlex({
          flex: display,
          direction: dir,
          gap: options.display.gap,
          align: dir === "column" ? align : "center",
          justify: dir === "row" ? align : "center",
          ...customProps.flex,
        }) + width,
    });

    return cssString;
  };

const generateEventInfoStyles =
  (field: IWidgetOptions, dateField: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = field;

    const variant = getEventInfoVariantStyles(
      options.display.layout as EventInfoVariant
    )[options.display.direction as EventInfoDirection];

    const containerStyles = variant.eventInfoStyles;

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content:
        generateDefaultStyles(options) +
        styleObjectToStringAdvanced({
          ...containerStyles,
          ...options.display.layoutAlignment,
          gap: getPxValueFromNumber(options.display.gap),
        }),
    });

    cssString += generateCssClassWithContent({
      className: dateField.options._cssClass,
      content: styleObjectToStringAdvanced({
        ...variant.dateTimeStyles,
      }),
    });

    const sizeStyle: ElementStyle = {};

    if (options.size.width.type === ResizingType.FILL) {
      sizeStyle.display = "block";
    } else {
      sizeStyle.display = "inline-flex";
    }

    if (options.size.height.type === ResizingType.FILL) {
      sizeStyle.height = "100%";
    } else {
      sizeStyle.height = "fit-content";
    }

    cssString += generateCssClassWithContent({
      className: options._containerCssClass,
      content: styleObjectToStringAdvanced(sizeStyle),
    });

    return cssString;
  };

const generateTimerValueStyles =
  (field: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = field;

    cssString += generateCssClassWithContent({
      className: options.numbers._cssClass,
      content: options.numbers,
      customFunction: generateDefaultStyles,
    });

    cssString += generateCssClassWithContent({
      className: options.caption._cssClass,
      content: options.caption,
      customFunction: generateDefaultStyles,
    });
    return cssString;
  };

const generateTeamStyles =
  (field: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = field;

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content:
        generateDefaultStyles(options) +
        generateWidgetHeight(options.size.height),
    });

    // cssString += generateCssClassWithContent({
    //   className: options._iconCssClass,
    //   content: `height: ${getPxValueFromNumber(options.icon.size)};`,
    // });

    return cssString;
  };

const generateInputSuffixStyles =
  (field: IWidgetOptions, cssClass: string) =>
  (cssString: string): string => {
    const { options } = field;

    cssString += generateCssClassWithContent({
      className: cssClass,
      content:
        generateTextColor(options.placeholderColor) +
        generateFontSize(options.theme),
    });

    return cssString;
  };

type CustomProps = {
  flex?: any;
  reverse?: boolean;
};

const generateFlexStyles =
  (
    field: IWidgetOptions,
    cssClass: string,
    dir = "column",
    customProps: CustomProps = { flex: {}, reverse: false }
  ) =>
  (cssString: string): string => {
    const { options } = field;
    const displayOptions = options.display || options;

    const align = getFlexPosition(displayOptions.alignment || "center");
    const currDir = customProps.reverse ? `${dir}-reverse` : dir;

    cssString += generateCssClassWithContent({
      className: cssClass,
      content: generateFlex({
        flex: "flex",
        direction: currDir,
        gap: displayOptions.gap,
        align: dir === "column" ? align : "center",
        justify: dir === "row" ? align : "center",
        ...customProps.flex,
      }),
    });

    return cssString;
  };

const generateBetsContainerStyles =
  (field: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = field;

    if (options.layout === DisplayOrientation.VERTICAL) {
      return cssString;
    }

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      pseudoClassName: " > div",
      content: "flex-basis: 50%;",
    });

    return cssString;
  };

const generateCustomBetAmountsStyles =
  (field: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = field;

    const isHorizontal =
      options.display.layout === DisplayOrientation.HORIZONTAL;

    cssString += generateCssClassWithContent({
      className: options._elCssClass,
      content:
        generateFlex({
          flex: "flex",
          align: "flex-start",
          justify: isHorizontal ? "space-between" : "flex-start",
          direction: isHorizontal ? "row" : "column",
        }) + "flex:1;",
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: generateFlex({
        flex: "flex",
        align: "stretch",
        justify: "center",
        direction: isHorizontal ? "column" : "row",
        gap: options.display.gap,
      }),
    });

    return cssString;
  };

export const generateTopMatchFenixCssString = (
  widget: IPageContentWidget
): string => {
  widget.options._cssClass = generateClassName(widget.name);
  widget.options._contentCssClass = generateClassName("content-container");
  widget.options._successCssClass = generateClassName("success-screen");
  const prefilled = prefillWithClasses(widget.content);

  prefilled.title.options.icon._cssClass = generateClassName("title_icon");

  prefilled.timer_value.options.numbers._cssClass = generateClassName(
    "timer_value_numbers"
  );
  prefilled.timer_value.options.caption._cssClass = generateClassName(
    "timer_value_caption"
  );

  prefilled.event_info.options._containerCssClass = generateClassName(
    "event_info_container"
  );

  prefilled.team.options._iconCssClass = generateClassName("team_icon");
  const teamDir =
    prefilled.team.options.icon.position === Alignment.LEFT ? "row" : "column";
  prefilled.team.options.icon._cssClass = generateClassName("team_icon");

  prefilled.date.options._elCssClass = generateClassName("date_el");
  prefilled.date.options.icon._cssClass = generateClassName("date_icon");

  const dateDir =
    prefilled.date.options.display.layout === DisplayOrientation.HORIZONTAL
      ? "row"
      : "column";

  const marketsDir =
    prefilled.markets.options.display.layout === DisplayOrientation.HORIZONTAL
      ? "row"
      : "column";

  prefilled.outcomes.options.name._cssClass =
    generateClassName("bet_items_name");
  prefilled.outcomes.options.coef._cssClass =
    generateClassName("bet_items_coef");
  prefilled.outcomes.options._itemSelectedCssClass =
    prefilled.outcomes.options._cssClass + '[data-selected="true"]';

  const betsDir =
    prefilled.bets.options.layout === DisplayOrientation.HORIZONTAL
      ? "row"
      : "column";

  prefilled.bets.options._contentCssClass = generateClassName("bets_content");

  prefilled.input.options._suffixCssClass = generateClassName("input_suffix");

  const notEnoughDir =
    prefilled.not_enough_container.options.display.layout ===
    DisplayOrientation.HORIZONTAL
      ? "row"
      : "column";
  prefilled.not_enough_container.options._depositCssClass = generateClassName(
    "not_enough_container_deposit"
  );
  prefilled.not_enough_container.options.icon._cssClass = generateClassName(
    "not_enough_container_icon"
  );
  prefilled.not_enough_container.options._titleCssClass = generateClassName(
    "not_enough_container_title"
  );

  const arrows = widget.content["arrows"];
  arrows.options._cssClass = generateClassName("arrows");
  arrows.options._containerCssClass = generateClassName("arrowsContainer");
  arrows.options.button._cssClass = generateClassName("arrowsButton");

  const pagination = widget.content["pagination"];
  pagination.options._cssClass = generateClassName("pagination");
  pagination.options._dotsCssClass = generateClassName("paginationDots");

  const buttonsDir =
    prefilled.button_group.options.display.layout ===
    DisplayOrientation.HORIZONTAL
      ? "row"
      : "column";

  /* 
    Success
  */

  const successMessagehDir =
    prefilled.success_message.options.display.layout ===
    DisplayOrientation.HORIZONTAL
      ? "row"
      : "column";
  prefilled.success_message.options._btnCssClass = generateClassName(
    "success_message_btn"
  );
  prefilled.success_message.options.icon._cssClass = generateClassName(
    "success_message_icon"
  );
  prefilled.success_message.options._titleCssClass = generateClassName(
    "success_message_title"
  );

  prefilled.bet_name.options._containerCssClass =
    generateClassName("bet_name_container");

  prefilled.result_bet_amounts.options.label._cssClass = generateClassName(
    "result_bet_amounts_label"
  );
  prefilled.result_bet_amounts.options.value._cssClass = generateClassName(
    "result_bet_amounts_value"
  );
  prefilled.result_bet_amounts.options._elCssClass = generateClassName(
    "result_bet_amounts_el"
  );

  return pipeSync<string>(
    generateStringDefault(widget),
    generateStringDefault(prefilled.match_cards),
    generateFlexDisplayStyles(prefilled.match_cards, "column", {
      flex: {
        align: prefilled.match_cards.options.display.align.justifyContent,
        justify: prefilled.match_cards.options.display.align.alignItems,
      },
    }),
    generateCustomStyles(prefilled.match_cards.options._cssClass, {
      "width": "100%",
    }),

    generateTitleCssString(prefilled.title),
    generateIconSizeCssString(prefilled.title.options.icon),
    generateIconCssString(prefilled.title.options.icon, "color", false),
    generateFlexibleImageCssString(prefilled.image as IWidgetField),
    generateContentStyles(prefilled.content),
    generateFlexDisplayStyles(prefilled.timer),
    generateStringDefault(prefilled.timer_header),
    generateFlexDisplayStyles(prefilled.timer_header, "row"),

    generateFlexDisplayStyles(prefilled.timer_counter, "row", {
      flex: {
        align: "stretch",
      },
    }),
    generateStringDefault(prefilled.timer_counter),

    generateStringDefault(prefilled.timer_value),
    generateFlexDisplayStyles(prefilled.timer_value, "column"),
    generateTimerValueStyles(prefilled.timer_value),

    generateFlexDisplayStyles(prefilled.timer_separator, "column", {
      flex: {
        align: "center",
        justify: getFlexPosition(
          prefilled.timer_separator.options.display.alignment
        ),
      },
    }),
    generateStringDefault(prefilled.timer_separator),

    generateEventInfoStyles(prefilled.event_info, prefilled.date),

    generateTeamStyles(prefilled.team),
    generateFlexDisplayStyles(prefilled.team, teamDir, {
      flex: {
        gap: prefilled.team.options.icon.gap,
      },
    }),
    generateCustomStyles(prefilled.team.options.icon._cssClass, {
      "height": getPxValueFromNumber(prefilled.team.options.icon.size),
    }),

    generateStringDefault(prefilled.date),
    generateFlexStyles(
      prefilled.date,
      prefilled.date.options._cssClass,
      dateDir
    ),
    generateFlexStyles(
      prefilled.date,
      prefilled.date.options._elCssClass,
      "row",
      {
        flex: {
          gap: prefilled.team.options.icon.gap,
        },
      }
    ),
    generateIconSizeCssString(prefilled.date.options.icon),
    generateIconCssString(prefilled.date.options.icon, "color", false),

    generateStringDefault(prefilled.markets),
    generateFlexStyles(
      prefilled.markets,
      prefilled.markets.options._cssClass,
      marketsDir,
      {
        flex: {
          align: "stretch",
        },
      }
    ),
    generateBetItemsCssString(prefilled.outcomes as IWidgetField),

    generateStringDefault(prefilled.bets),
    generateFlexStyles(
      prefilled.bets,
      prefilled.bets.options._cssClass,
      betsDir,
      {
        reverse: prefilled.bets.options.direction === "reverse",
        flex: {
          align: "stretch",
        },
      }
    ),
    generateBetsContainerStyles(prefilled.bets),

    generateFlexStyles(
      prefilled.bet_amounts,
      prefilled.bets.options._contentCssClass,
      "row",
      {
        flex: {
          align: "stretch",
        },
      }
    ),
    generateCustomStyles(prefilled.bet_amounts.options._cssClass, {
      "flex": "1",
      "text-align": "center",
    }),
    generateStringWithStates(prefilled.bet_amounts, false),
    generateFieldsWithStatesCssString(prefilled.input, null, widget),
    generateInputSuffixStyles(
      prefilled.input,
      prefilled.input.options._suffixCssClass
    ),

    generateStringDefault(prefilled.not_enough_container),
    generateStringDefault({
      ...prefilled.not_enough_container,
      options: {
        ...prefilled.not_enough_container.options.deposit,
        _cssClass: prefilled.not_enough_container.options._depositCssClass,
      },
    }),
    generateFlexStyles(
      prefilled.not_enough_container,
      prefilled.not_enough_container.options._cssClass,
      notEnoughDir,
      {
        flex: {
          align: "center",
          justify: "space-between",
        },
      }
    ),

    generateIconSizeCssString(prefilled.not_enough_container.options.icon),
    generateIconCssString(
      prefilled.not_enough_container.options.icon,
      "color",
      false
    ),

    generateFlexStyles(
      prefilled.not_enough_container,
      prefilled.not_enough_container.options._titleCssClass,
      "row",
      {
        flex: {
          gap: prefilled.not_enough_container.options.icon.gap,
          align: "center",
        },
      }
    ),

    generateStringDefault(prefilled.button_group),
    generateFlexStyles(
      prefilled.button_group,
      prefilled.button_group.options._cssClass,
      buttonsDir
    ),
    generateButtonCssString(prefilled.button_1 as IWidgetField),
    generateButtonCssString(prefilled.button_2 as IWidgetField),

    /* 
      Success
    */

    generateStringDefault(prefilled.success_message),
    generateStringDefault({
      ...prefilled.success_message,
      options: {
        ...prefilled.success_message.options.print,
        _cssClass: prefilled.success_message.options._btnCssClass,
      },
    }),
    generateFlexStyles(
      prefilled.success_message,
      prefilled.success_message.options._cssClass,
      successMessagehDir,
      {
        flex: {
          align: "center",
          justify: "space-between",
        },
      }
    ),

    generateIconSizeCssString(prefilled.success_message.options.icon),
    generateIconCssString(
      prefilled.success_message.options.icon,
      "color",
      false
    ),

    generateFlexStyles(
      prefilled.success_message,
      prefilled.success_message.options._titleCssClass,
      "row",
      {
        flex: {
          gap: prefilled.success_message.options.icon.gap,
          align: "center",
        },
      }
    ),

    generateStringDefault(prefilled.bet_card),
    generateFlexStyles(
      prefilled.bet_card,
      prefilled.bet_card.options._cssClass,
      "column",
      {
        flex: {
          align: "stretch",
        },
      }
    ),

    generateStringDefault(prefilled.bets_type),

    generateStringDefault(prefilled.event_container),
    generateFlexStyles(
      prefilled.event_container,
      prefilled.event_container.options._cssClass,
      "column",
      {
        flex: {
          align: "stretch",
        },
      }
    ),

    generateStringDefault(prefilled.event_name),
    generateStringDefault(prefilled.bet_market),
    generateStringDefault(prefilled.bet_name),
    generateStringDefault(prefilled.coefficent),
    generateCustomStyles(prefilled.bet_name.options._containerCssClass, {
      display: "flex",
      "align-items": "center",
      "justify-content": "space-between",
    }),
    generateCustomBetAmountsStyles(prefilled.result_bet_amounts),
    generateStringDefault({
      options: {
        ...prefilled.result_bet_amounts.options.label,
      },
    }),
    generateStringDefault({
      options: {
        ...prefilled.result_bet_amounts.options.value,
      },
    }),

    generateButtonCssString(prefilled.continue_button as IWidgetField),

    generateCustomStyles(widget.options._successCssClass, {
      display: "flex",
      "align-items": "stretch",
      "flex-direction": "column",
      "justify-content": "center",
      gap: getPxValueFromNumber(widget.options.success.gap),
    }),

    generateArrows(arrows),
    generatePagination(pagination)
  )("");
};
