import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";
import { generateIconCssString } from "~~/assets/utils/widget-css/widgets/racing-sportsbook-live-widget-css";
import { generateListStartTimeCssString } from "~~/assets/utils/widget-css/widgets/sportsbook-widget-css";
import {
  Alignment,
  DisplayOrientation,
  ResizingType,
} from "~~/models/widgets/widget-controls.model";
import { generateBetItemsCssString } from "~~/assets/utils/widget-css/widgets/sportsbook-live-widget-css";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateStringDefault,
  generateStringWithStates,
} from "../utils/pipe-helper-functions";
import {
  generateFlex,
  generateGrid,
  generateMaxWidth,
  generateTextColor,
  generateWidth,
} from "../helpers";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
} from "../compiler/default-css-compiler";
import {
  generateIconSizeCssString,
  generateStageButtonCssString,
  generateIconCssString as generateIconColorCssString,
} from "../utils/form-helper-functions";
import { getFlexPosition } from "../../widget-settings";
import { getPxValueFromNumber } from "../..";

const generateTitleCssString =
  (field: IWidgetOptions) =>
  (cssString: string): string => {
    const flex = generateFlex({
      flex: "flex",
      align: "center",
      justify: "flex-start",
      gap: field.options.display.distance || "",
    });

    cssString += generateCssClassWithContent({
      className: field.options._cssClass,
      content: flex + "white-space:nowrap;",
    });

    cssString += generateCssClassWithContent({
      className: field.options._cssClass,
      pseudoClassName: `:hover .${field.options.icon._cssClass}`,
      content: generateDefaultStyles(field.options.states.hover.icon),
    });

    return cssString;
  };

export const generateSportEventsContainerCssString =
  (field: IWidgetOptions, sportsContainer: IWidgetOptions, noMargin = false) =>
  (cssString: string): string => {
    const { options } = field;

    const layout = options.display?.layout || options.columns;
    const grid = generateGrid({
      templateColumns: `repeat(${layout}, minmax(0, 1fr))`,
      rowGap: options.display?.distance || options.distance,
      columnGap: options.display?.distance || options.distance,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content:
        grid +
        generateDefaultStyles(options) +
        `margin-bottom: ${getPxValueFromNumber(
          noMargin
            ? 0
            : sportsContainer.options.display?.distanceBetweenLeagueEvents ||
                sportsContainer.options.distanceBetweenLeagueEvents
        )}`,
    });

    return cssString;
  };

export const generateSportEventCardCssString =
  (
    field: IWidgetOptions,
    eventInfoField: IWidgetOptions,
    event_name?: IWidgetOptions
  ) =>
  (cssString: string): string => {
    const { options } = field;

    const flex = generateFlex({
      flex: "flex",
      align: "stretch",
      justify: "flex-start",
      direction: "column",
      gap:
        field.options.display?.distance || field.options.contentItemsDistance,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: flex,
    });

    const layout = field.options.display?.layout || field.options.contentLayout;

    const primaryFlex = generateFlex({
      flex: "flex",
      align: layout === 1 ? "center" : "stretch",
      justify: layout === 1 ? "space-between" : "flex-start",
      direction: layout === 1 ? "row" : "column",
      gap:
        field.options.display?.distance || field.options.contentItemsDistance,
    });

    const marketsPrimaryFlex = generateFlex({
      flex: "flex",
      align: layout === 1 ? "center" : "stretch",
      justify: "flex-end",
      gap:
        field.options.display?.distance || field.options.contentItemsDistance,
    });

    cssString += generateCssClassWithContent({
      className: options._primaryCssClass,
      content: primaryFlex,
    });
    // TODO: remake this, need to add config with width for this element
    if (layout === 1) {
      cssString += generateCssClassWithContent({
        className: options._marketContainerSuspendedCssClass,
        content: "width: 145px;",
      });

      cssString += generateCssClassWithContent({
        className: eventInfoField.options._cssClass,
        content: "overflow:hidden;flex-basis: 100%;",
      });

      cssString += generateCssClassWithContent({
        className: eventInfoField.options._itemCssClass,
        content: `flex: unset !important;`,
      });

      cssString += generateCssClassWithContent({
        className: options._marketPrimaryCssClass,
        content: marketsPrimaryFlex + "width:100%;",
      });
    }

    if (event_name) {
      for (const state in field.options.states) {
        const color = generateTextColor(field.options.states[state].color);

        cssString += generateCssClassWithContent({
          className: field.options._cssClass,
          pseudoClassName: `:${state} .${event_name.options._cssClass}`,
          content: color,
        });
      }
    }

    return cssString;
  };

export const generateSportEventHeaderCssString =
  (field: IWidgetOptions, favIconField: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = field;

    const flex = generateFlex({
      flex: "flex",
      align: "flex-start",
      justify: "space-between",
      direction: "row",
      gap: field.options.display?.distance || field.options.distance,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: generateDefaultStyles(options) + flex + "flex-grow:1;",
    });

    const detailsFlex = generateFlex({
      flex: "flex",
      align: "center",
      justify: "flex-start",
      direction: "row",
      gap: field.options.display?.distance || field.options.distance,
    });

    cssString += generateCssClassWithContent({
      className: options._detailsCssClass,
      content: detailsFlex,
    });

    const contentLayout =
      field.options.display?.layout || field.options.contentLayout;
    const isHorizontal = contentLayout === DisplayOrientation.HORIZONTAL;

    const getDirection = (layout: string, position: string): string => {
      if (layout === DisplayOrientation.HORIZONTAL) {
        if (position === "left") {
          return "row";
        }

        return "row-reverse";
      }

      if (position === "top") {
        return "column";
      }

      return "column-reverse";
    };

    let position;

    if (isHorizontal && field.options.leaguePosition) {
      position = field.options.leaguePosition.horizontal;
    } else if (field.options.leaguePosition) {
      position = field.options.leaguePosition.vertical;
    } else {
      position = field.options.display?.position;
    }

    const topFlex = generateFlex({
      flex: "flex",
      align: isHorizontal ? "center" : "flex-start",
      justify: "flex-start",
      direction: getDirection(contentLayout, position),
      gap: field.options.display?.distance || field.options.distance,
    });

    cssString += generateCssClassWithContent({
      className: options._topCssClass,
      content:
        topFlex +
        "width: 100%;" +
        "min-width: 0;max-width:100%;" +
        (!isHorizontal ? "align-self: stretch;flex:1;" : ""),
    });

    const favIconAlignment =
      field.options.display?.iconAlignment || field.options.iconAlignment;

    cssString += generateCssClassWithContent({
      className: favIconField.options._cssClass,
      content: `align-self: ${getFlexPosition(favIconAlignment)}`,
    });

    const topDetailsflex = generateFlex({
      flex: "flex",
      align: "center",
      justify: "flex-start",
      gap: field.options.display?.distance || field.options.distance,
    });

    cssString += generateCssClassWithContent({
      className: options._topDetailsCssClass,
      content:
        topDetailsflex +
        "min-width: 0;max-width:100%;" +
        (!isHorizontal ? "align-self: stretch;" : ""),
    });

    return cssString;
  };

export const generateEventInfoCssString =
  (
    field: IWidgetOptions,
    eventScoreField: IWidgetOptions,
    eventNameField: IWidgetOptions
  ) =>
  (cssString: string): string => {
    const { options } = field;

    const contentLayout =
      field.options.display?.layout || field.options.displayMode;
    const isHorizontal = contentLayout === DisplayOrientation.HORIZONTAL;

    const flex = generateFlex({
      flex: "flex",
      align: isHorizontal ? "center" : "stretch",
      justify: "center",
      direction: isHorizontal ? "row" : "column",
      gap: field.options.display?.distance || field.options.distance,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: generateDefaultStyles(options) + flex + "width:100%;",
    });

    const itemFlex = generateFlex({
      flex: "flex",
      align: "center",
      justify: "flex-end",
      direction: "row",
    });

    cssString += generateCssClassWithContent({
      className: options._itemCssClass,
      content: itemFlex + "flex:1;",
    });

    const lastChildContent = isHorizontal ? "flex-direction: row-reverse" : "";

    cssString += generateCssClassWithContent({
      className: options._itemCssClass,
      content: lastChildContent,
      pseudoClassName: ":last-child",
    });

    cssString += generateCssClassWithContent({
      className: eventScoreField.options._cssClass,
      content: "display: inline-block;",
    });

    cssString += generateCssClassWithContent({
      className: eventNameField.options._cssClass,
      content:
        "overflow :hidden;text-overflow: ellipsis;white-space: nowrap;flex:1;",
    });

    if (isHorizontal) {
      const currAlignment = eventNameField.options.alignment;
      let secondElAlignment: string;

      if (currAlignment === Alignment.LEFT) {
        secondElAlignment = Alignment.RIGHT;
      } else if (currAlignment === Alignment.RIGHT) {
        secondElAlignment = Alignment.LEFT;
      } else {
        secondElAlignment = Alignment.CENTER;
      }

      cssString += generateCssClassWithContent({
        className: options._itemCssClass,
        pseudoClassName: `:last-child .${eventNameField.options._cssClass}`,
        content: `text-align: ${secondElAlignment}`,
      });
    }

    return cssString;
  };

export const generateBetItemWidthFixCssString =
  (field: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = field;

    cssString += generateCssClassWithContent({
      className: options._itemCssClass,
      pseudoClassName: ":last-child:first-child",
      content: `margin-right: ${getPxValueFromNumber(
        options.distance / 2
      )};${generateWidth(100, "%")}`,
    });

    return cssString;
  };

export const generateCustomBetItemCssString =
  (field: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = field;

    const itemsFlex = generateFlex({
      flex: "flex",
      align: "center",
      justify: "center",
      direction: "column",
    });

    cssString += generateCssClassWithContent({
      className: options._itemCssClass,
      content: itemsFlex,
    });

    cssString += generateCssClassWithContent({
      className: options._itemCssClass,
      content: "padding: 0; margin: 0;",
      pseudoClassName: " > *",
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content:
        options.displayMode === DisplayOrientation.HORIZONTAL
          ? "display:flex !important;"
          : "display:flex !important; flex-direction:column;",
    });

    return cssString;
  };

export const generateSuspendedBetItemCssString =
  (field: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = field;

    cssString += generateCssClassWithContent({
      className: options._itemCssClass,
      content: generateDefaultStyles(options.states.suspended),
      pseudoClassName: `[data-suspended="true"]`,
    });

    cssString += generateCssClassWithContent({
      className: options._itemCssClass,
      pseudoClassName: `[data-suspended="true"]`,
      childClassName: options.name._cssClass,
      content: generateDefaultStyles(options.states.suspended?.name, ["theme"]),
    });

    cssString += generateCssClassWithContent({
      className: options._itemCssClass,
      pseudoClassName: `[data-suspended="true"]`,
      childClassName: options.coef._cssClass,
      content: generateDefaultStyles(options.states.suspended?.coef, ["theme"]),
    });

    const flex = generateFlex({
      flex: "flex",
      align: "center",
      justify: "center",
      direction: "column",
    });

    cssString += generateCssClassWithContent({
      className: options._itemCssClass,
      pseudoClassName: `[data-suspended="true"]`,
      content:
        flex + generateMaxWidth("100%", true) + "margin: 0; line-height: 2em;", // fix for button height
    });

    return cssString;
  };

export const generateFillOrHugCssString =
  (fieldCssClass: string, containerCssClass: string, resizing: string) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: containerCssClass,
      content: "display: flex;",
    });

    cssString += generateCssClassWithContent({
      className: fieldCssClass,
      content: resizing === ResizingType.FILL ? "flex: 1 1 100%;" : "",
    });

    return cssString;
  };

export const generateTextFillOrHugCssString =
  (fieldCssClass: string, resizing: string, containerCssClasses?: string[]) =>
  (cssString: string): string => {
    if (containerCssClasses) {
      containerCssClasses.forEach(c => {
        cssString += generateCssClassWithContent({
          className: c,
          content: "flex: 1;align-self:stretch !important;",
        });
      });
    }

    cssString += generateCssClassWithContent({
      className: fieldCssClass,
      content:
        resizing === ResizingType.FILL
          ? "flex: 1;align-self:stretch !important;"
          : "",
    });

    return cssString;
  };

export const generateRacingSportEventCardCssString =
  (field: IWidgetOptions, eventNameField: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = field;

    const layout = field.options.display?.layout || field.options.contentLayout;

    const flex = generateFlex({
      flex: "flex",
      align: "stretch",
      justify: "flex-start",
      direction: layout === DisplayOrientation.HORIZONTAL ? "row" : "column",
      gap:
        field.options.display?.distance || field.options.contentItemsDistance,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: flex,
    });

    const topFlex = generateFlex({
      flex: "flex",
      align: "center",
      justify: "flex-start",
      gap:
        field.options.display?.distance || field.options.contentItemsDistance,
    });

    cssString += generateCssClassWithContent({
      className: options._topCssClass,
      content: topFlex + "flex: 1;",
    });

    cssString += generateCssClassWithContent({
      className: eventNameField.options._cssClass,
      content: "flex: 1;",
    });

    return cssString;
  };

export const generateSportsBookMobileLiveFenixWidgetCssString = (
  widget: IPageContentWidget
): string => {
  const title = widget.content["title"];
  const title_button = widget.content["title_button"];
  const leagues_titles = widget.content["leagues_titles"];
  const sport_events_container = widget.content["sport_events_container"];
  const event_cards = widget.content["event_cards"];
  const event_league = widget.content["event_league"];
  const stream_icon = widget.content["stream_icon"];
  const favourites_button = widget.content["favourites_button"];
  const event_time = widget.content["event_time"];
  const event_period = widget.content["event_period"];
  const event_header = widget.content["event_header"];
  const event_info = widget.content["event_info"];
  const event_name = widget.content["event_name"];
  const event_score = widget.content["event_score"];
  const bet_items = widget.content["bet_items"];
  const event_button = widget.content["event_button"];
  const sports_container = widget.content["sports_container"];
  const no_events_title = widget.content["no_events_title"];
  // const event_suspended_status = widget.content["event_suspended_status"];

  const racing_events_container = widget.content["racing_events_container"];
  const racing_event_cards = widget.content["racing_event_cards"];
  const racing_event_stream_icon = widget.content["racing_event_stream_icon"];
  const racing_event_name = widget.content["racing_event_name"];
  const racing_event_status = widget.content["racing_event_status"];
  const racing_event_favourites_button =
    widget.content["racing_event_favourites_button"];
  const racing_event_button = widget.content["racing_event_button"];

  widget.options._cssClass = generateClassName("SportsMenuLiveFenixWidget");

  sports_container.options._cssClass = generateClassName("sports_container");

  title.options._cssClass = generateClassName("title");
  title.options.icon._cssClass = generateClassName("title_icon");

  no_events_title.options._cssClass = generateClassName("no_events_title");

  title_button.options._cssClass = generateClassName("title_button");
  title_button.options.iconSettings._cssClass =
    generateClassName("title_button_icon");

  leagues_titles.options._cssClass = generateClassName("leagues_titles");
  // event_suspended_status.options._cssClass = generateClassName(
  //   "event_suspended_status"
  // );
  sport_events_container.options._cssClass = generateClassName(
    "sport_events_container"
  );

  event_cards.options._cssClass = generateClassName("event_cards");
  event_cards.options._primaryCssClass = generateClassName(
    "event_cards-primary"
  );
  event_cards.options._marketPrimaryCssClass = generateClassName(
    "event_cards-markets-primary"
  );
  event_cards.options._marketContainerCssClass =
    generateClassName("event_cards_market");
  event_cards.options._marketContainerSuspendedCssClass = generateClassName(
    "event_cards_market_suspended"
  );

  event_league.options._cssClass = generateClassName("event_league");
  stream_icon.options._cssClass = generateClassName("stream_icon");
  favourites_button.options._cssClass = generateClassName("favourites_button");
  event_time.options._cssClass = generateClassName("event_time");
  event_time.options.icon._cssClass = generateClassName("event_time_icon");
  event_period.options._cssClass = generateClassName("event_period");

  event_header.options._cssClass = generateClassName("event_header");
  event_header.options._detailsCssClass = generateClassName(
    "event_header_details"
  );
  event_header.options._topCssClass = generateClassName("event_header_top");
  event_header.options._topDetailsCssClass = generateClassName(
    "event_header_top_details"
  );

  event_info.options._cssClass = generateClassName("event_info");
  event_info.options._itemCssClass = generateClassName("event_info_item");
  event_name.options._cssClass = generateClassName("event_name");
  event_score.options._cssClass = generateClassName("event_score");

  bet_items.options._cssClass = generateClassName("bet_items");

  bet_items.options._itemCssClass = generateClassName("bet_items_item");
  bet_items.options._itemSelectedCssClass = bet_items.options._itemCssClass;
  bet_items.options._itemUpCssClass = bet_items.options._itemCssClass;
  bet_items.options._itemDownCssClass = bet_items.options._itemCssClass;

  bet_items.options.name._cssClass = generateClassName("bet_items_name");
  bet_items.options.coef._cssClass = generateClassName("bet_items_coef");

  event_button.options._cssClass = generateClassName("event_button");
  event_button.options._containerCssClass = generateClassName(
    "event_button_container"
  );
  event_button.options.iconSettings._cssClass =
    generateClassName("event_button_icon");

  racing_events_container.options._cssClass = generateClassName(
    "racing_events_container"
  );
  racing_event_cards.options._cssClass =
    generateClassName("racing_event_cards");
  racing_event_cards.options._topCssClass = generateClassName(
    "racing_event_cards_top"
  );
  racing_event_stream_icon.options._cssClass = generateClassName(
    "racing_event_stream_icon"
  );
  racing_event_name.options._cssClass = generateClassName("racing_event_name");
  racing_event_status.options._cssClass = generateClassName(
    "racing_event_status"
  );
  racing_event_favourites_button.options._cssClass = generateClassName(
    "racing_event_favourites_button"
  );
  racing_event_button.options._cssClass = generateClassName(
    "racing_event_button"
  );

  racing_event_button.options.iconSettings._cssClass = generateClassName(
    "racing_event_button_icon"
  );

  racing_event_button.options._containerCssClass = generateClassName(
    "racing_event_button_container"
  );

  return pipeSync<string>(
    generateStringDefault(widget),
    generateStringDefault(sports_container),
    generateStringDefault(no_events_title),
    generateTitleCssString(title),
    generateStringWithStates(title, false),
    generateIconColorCssString(title.options.icon),
    generateIconSizeCssString(title.options.icon),
    generateStageButtonCssString(title_button as IWidgetField),
    generateStringDefault(leagues_titles),
    generateSportEventsContainerCssString(
      sport_events_container,
      sports_container
    ),
    generateSportEventCardCssString(event_cards, event_info),
    generateStringWithStates(event_cards, false),
    generateStringDefault(event_league),
    generateTextFillOrHugCssString(
      event_league.options._cssClass,
      event_league.options.display,
      [
        event_header.options._topDetailsCssClass,
        event_header.options._topCssClass,
      ]
    ),
    generateIconCssString(stream_icon),
    generateIconCssString(favourites_button, {
      size: "size",
      color: "color",
    }),
    generateListStartTimeCssString(event_time as IWidgetField),
    generateStringDefault(event_period),
    generateTextFillOrHugCssString(
      event_period.options._cssClass,
      event_period.options.display,
      [event_header.options._detailsCssClass]
    ),
    generateSportEventHeaderCssString(event_header, favourites_button),
    generateStringDefault(event_name),
    generateEventInfoCssString(event_info, event_score, event_name),
    generateStringDefault(event_score),
    generateBetItemsCssString(bet_items as IWidgetField, [
      "templateColumns",
      // "columnGap",
    ]),
    generateCustomBetItemCssString(bet_items),
    generateSuspendedBetItemCssString(bet_items),
    generateBetItemWidthFixCssString(bet_items),
    generateStageButtonCssString(event_button as IWidgetField),
    generateFillOrHugCssString(
      event_button.options._cssClass,
      event_button.options._containerCssClass,
      event_button.options.buttonDisplaySettings.resizing
    ),

    /* 
      Racing
    */
    generateSportEventsContainerCssString(
      racing_events_container,
      sports_container
    ),
    generateRacingSportEventCardCssString(
      racing_event_cards,
      racing_event_name
    ),
    generateStringWithStates(racing_event_cards, false),
    generateIconCssString(racing_event_stream_icon),
    generateStringDefault(racing_event_name),
    generateStringDefault(racing_event_status),
    generateIconCssString(racing_event_favourites_button, {
      size: "size",
      color: "color",
    }),
    generateStageButtonCssString(racing_event_button as IWidgetField),
    generateFillOrHugCssString(
      racing_event_button.options._cssClass,
      racing_event_button.options._containerCssClass,
      racing_event_button.options.buttonDisplaySettings.resizing
    )
  )("");
};
