<template>
  <div>
    <!-- 
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        @close="resetStates"
        @blur-tab="resetStates"
        @change="resetStates"
      >
        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #widgetSettings-data>
          <WidgetDynamicParams
            :widget="widget"
            :initial-value="bindingParams"
          />
        </template>

        <template #header-design>
          <ControlsList
            :widget="widget"
            :controls="headerDesignControls"
          />
        </template>

        <template #header-content>
          <ControlsList
            :widget="widget"
            :controls="headerContentControls"
          />
        </template>

        <template #title>
          <ControlsList
            :widget="widget"
            :controls="titleControls"
          />
        </template>

        <template #header_button-design>
          <ControlsList
            :widget="widget"
            :controls="headerButtonDesignControls"
          />
        </template>

        <template #header_button-content>
          <ControlsList
            :widget="widget"
            :controls="headerButtonContentControls"
          />
        </template>

        <template #header_button-states>
          <ControlsList
            :widget="widget"
            :controls="headerButtonStatesControls"
          />
        </template>

        <template #games_list-design>
          <ControlsList
            :widget="widget"
            :controls="gamesListDesignControls"
          />
        </template>

        <template #games_list-content>
          <ControlsList
            :widget="widget"
            :controls="gamesListContentControls"
          />
        </template>

        <template #game_name>
          <ControlsList
            :widget="widget"
            :controls="gameNameControls"
          />
        </template>

        <template #game_cards-design>
          <ControlsList
            :widget="widget"
            :controls="gameCardsDesignControls"
          />
        </template>

        <template #game_cards-content>
          <ControlsList
            :widget="widget"
            :controls="gameCardsContentControls"
          />
        </template>

        <template #all_games_card-design>
          <ControlsList
            :widget="widget"
            :controls="allGameCardDesignControls"
          />
        </template>

        <template #all_games_card-content>
          <ControlsList
            :widget="widget"
            :controls="allGameCardContentControls"
          />
        </template>

        <template #game_cover-design>
          <ControlsList
            :widget="widget"
            :controls="gameCoverDesignControls"
          />
        </template>

        <template #game_cover-content>
          <ControlsList
            :widget="widget"
            :controls="gameCoverContentControls"
          />
        </template>

        <template #game_cover-states>
          <ControlsList
            :widget="widget"
            :controls="gameCoverStatesControls"
          />
        </template>

        <template #favorite_icon>
          <ControlsList
            :widget="widget"
            :controls="casinoListFavIconControls"
          />
        </template>

        <template #promo_tag-design>
          <ControlsList
            :widget="widget"
            :controls="casinoListGameTagButtonDesignControls"
          />
        </template>

        <template #promo_tag-content>
          <ControlsList
            :widget="widget"
            :controls="casinoListGameTagButtonContentControls"
          />
        </template>

        <template #promo_tag-states>
          <ControlsList
            :widget="widget"
            :controls="casinoListGameTagButtonStateControls"
          />
        </template>

        <template #hover_content>
          <ControlsList
            :widget="widget"
            :controls="casinoListHoverContentControls"
          />
        </template>

        <template #game_title>
          <ControlsList
            :widget="widget"
            :controls="casinoListGameTitleControls"
          />
        </template>

        <template #play_money_button-design>
          <ControlsList
            :widget="widget"
            :controls="casinoListPlayMoneyButtonDesignControls"
          />
        </template>

        <template #play_money_button-content>
          <ControlsList
            :widget="widget"
            :controls="casinoListPlayMoneyButtonContentControls"
          />
        </template>

        <template #play_money_button-states>
          <ControlsList
            :widget="widget"
            :controls="casinoListPlayMoneyButtonStateControls"
          />
        </template>

        <template #play_free_button-design>
          <ControlsList
            :widget="widget"
            :controls="casinoListPlayFreeButtonDesignControls"
          />
        </template>

        <template #play_free_button-content>
          <ControlsList
            :widget="widget"
            :controls="casinoListPlayFreeButtonContentControls"
          />
        </template>

        <template #play_free_button-states>
          <ControlsList
            :widget="widget"
            :controls="casinoListPlayFreeButtonStateControls"
          />
        </template>

        <template #games_button-design>
          <ControlsList
            :widget="widget"
            :controls="gamesButtonDesignControls"
          />
        </template>

        <template #games_button-content>
          <ControlsList
            :widget="widget"
            :controls="gamesButtonContentControls"
          />
        </template>

        <template #games_button-states>
          <ControlsList
            :widget="widget"
            :controls="gamesButtonStatesControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { State } from "~~/models/widgets/widget-controls.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useConfig } from "~~/composables/widgets/casino/smart-games/useCasinoSmartGamesConfig";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetDynamicParams } from "~~/components/dynamic-params/useWidgetDynamicParams";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
}>();

const emit = defineEmits<{
  (e: "update-state", event: { state: string; value: State }): void;
  (e: "reset-states"): void;
}>();

const widgetsStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsStore);
const states = toRef(() => props.states);

const { bindingParams } = useWidgetDynamicParams(
  {
    "casinoUrl": {
      label: "Casino Page URL",
      sources: ["custom"],
    },
    "dataSourceMode": {
      label: "Data source mode",
      description:
        "Possible values: Recommendations | Search result | Top games; Empty value means Top games",
      sources: ["custom"],
    },
    "searchPaginationMaxValue": {
      label: "Search pagination max value",
      description: "How much games will be displayed in the search result",
      sources: ["custom"],
    },
    "oneGameForMoneyPageUrl": {
      label: "One game for money page URL",
      sources: ["custom"],
      required: true,
    },
    "oneGameForFunPageUrl": {
      label: "One game for fun page URL",
      sources: ["custom"],
      required: true,
    },
  },
  {
    values: props.widget.options.bindingParams || {},
  }
);

const {
  DROPDOWN_ITEMS,
  TABS,

  widgetDesignControls,
  widgetContentControls,

  headerDesignControls,
  headerContentControls,

  titleControls,

  headerButtonDesignControls,
  headerButtonContentControls,
  headerButtonStatesControls,

  gamesListDesignControls,
  gamesListContentControls,

  gameNameControls,

  gameCardsDesignControls,
  gameCardsContentControls,

  allGameCardDesignControls,
  allGameCardContentControls,

  gameCoverDesignControls,
  gameCoverContentControls,
  gameCoverStatesControls,

  casinoListFavIconControls,

  casinoListGameTagButtonDesignControls,
  casinoListGameTagButtonContentControls,
  casinoListGameTagButtonStateControls,

  casinoListHoverContentControls,

  casinoListGameTitleControls,

  casinoListPlayMoneyButtonDesignControls,
  casinoListPlayMoneyButtonContentControls,
  casinoListPlayMoneyButtonStateControls,

  casinoListPlayFreeButtonDesignControls,
  casinoListPlayFreeButtonContentControls,
  casinoListPlayFreeButtonStateControls,

  gamesButtonDesignControls,
  gamesButtonContentControls,
  gamesButtonStatesControls,
} = useConfig(ref(props.widget), states, emit);

const resetStates = () => {
  emit("reset-states");
};

onBeforeUnmount(() => {
  emit("reset-states");
});
</script>
