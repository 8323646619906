<template>
  <div>
    <a-form-item
      label="Tag"
      name="tag"
      class="widget-tag-drawer__input"
    >
      <a-input
        :value="currentWidgetTagValue"
        readonly
      />
    </a-form-item>

    <a-form-item
      name="isApplyDataForCopiedWidgets"
      class="widget-tag-drawer__input"
    >
      <a-checkbox v-model:checked="isApplyDataForCopiedWidgets">
        Change data tab in widget
      </a-checkbox>
    </a-form-item>

    <div class="m-t-16 position-relative">
      <div
        v-if="errorText"
        class="m-b-16"
      >
        <a-tag color="error">{{ errorText }}</a-tag>
      </div>

      <CommonSpinner v-if="loading" />

      <template v-if="currentWidgetTagValue">
        <p class="fw-600">Similar widgets:</p>
        <a-table
          :data-source="similarWidgetsToSelected"
          :columns="tableColumns"
          :row-selection="rowSelection"
          :row-class-name="currentRowClassname"
          class="widget-tag-drawer__table"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'name'">
              <span>
                <span
                  v-if="record.id === widgetSettingsStore.selectedWidget?.id"
                  class="widget-tag-drawer__current"
                >
                  Current widget
                </span>

                <span class="widget-tag-drawer__name-details">
                  <span>
                    {{ record.name }}
                  </span>

                  <a-tooltip
                    v-if="
                      record.name !== widgetSettingsStore.selectedWidget?.name
                    "
                    trigger="hover"
                    placement="bottom"
                  >
                    <template #title>
                      Cannot apply options to different widget
                    </template>

                    <CommonIcon name="ant-design:question-circle-outlined" />
                  </a-tooltip>
                </span>
              </span>
            </template>

            <template v-if="column.key === 'pageName'">
              <a
                :href="`${VITE_DASHBOARD_BASE_URL}/pages/${record.page.pageId}/setup`"
                target="_blank"
              >
                {{ record.page.name }}
              </a>
            </template>

            <template v-if="column.key === 'pagePath'">
              <span>
                {{
                  record.page.routes &&
                  record.page.routes[
                    languagesStore.defaultLanguage!.codeWithRegion
                  ]
                }}
              </span>
            </template>
          </template>
        </a-table></template
      >

      <div
        v-if="ignoredPagesWithSimilarWidgets.length"
        class="m-t-16"
      >
        <p class="m-b-8">
          These pages were ignored. Please update them manually
        </p>
        <div>
          <p
            v-for="pageId in ignoredPagesWithSimilarWidgets"
            :key="pageId"
            class="m-b-0"
          >
            <a
              :href="`${VITE_DASHBOARD_BASE_URL}/pages/${pageId}/setup`"
              target="_blank"
            >
              {{ pagesWithErrors[pageId].route }}
            </a>
          </p>
        </div>
      </div>

      <!--  -->

      <div
        v-if="currentWidgetTagValue"
        class="widget-tag-drawer__btn-container m-t-16"
      >
        <a-button
          :disabled="applyLoading || loading || selected.length < 1"
          :loading="applyCurrentLoading"
          type="primary"
          @click="applyToCurrentWidget"
        >
          Apply to current widget
        </a-button>

        <a-button
          :disabled="
            applyCurrentLoading || loading || selected.length <= 1 || isFailed
          "
          :loading="applyLoading"
          ghost
          type="primary"
          @click="applyChangesToSimilar"
        >
          Apply to selected
        </a-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useLanguagesStore } from "~~/store/languages";
import {
  useGlobalPages,
  type WidgetWithMeta,
  type Page,
  type ChildrenWidgets,
} from "~~/composables/widgets-config/useGlobalPages";
import { VITE_DASHBOARD_BASE_URL } from "~~/constants";
import { handleActionSuccess } from "~~/helpers/handle-action-success";
import { handleActionError } from "~~/helpers/handle-action-error";
import { SUCCESSFULLY_UPDATED } from "~~/constants/notification-messages";
import { useMetaStore } from "~~/store/meta";
import { IPageContentWidget } from "~~/models/page.model";

const tableColumns = [
  {
    title: "Widget",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Page",
    dataIndex: "pageName",
    key: "pageName",
  },
  {
    title: "Path",
    dataIndex: "pagePath",
    key: "pagePath",
  },
];

const widgetSettingsStore = useWidgetSettingsStore();
const languagesStore = useLanguagesStore();
const metaStore = useMetaStore();

const drawerValue = computed<boolean>(() => {
  return widgetSettingsStore.showTagDrawer;
});

const {
  pagesContentData,
  pagesData,
  templatesData,
  pagesList,
  pagesWithErrors,

  fetchAllPages,
  fetchPagesContent,
  fetchTemplatesData,
  findSimilarTagWidgetsInGrid,

  applyWidgetChangesToList,
} = useGlobalPages(drawerValue);

const similarWidgetsToSelected = ref<WidgetWithMeta[]>([]);
const similarWidgetsChildren = ref<Record<string, IPageContentWidget[]>>({});
const selected = ref<string[]>([]);
const loading = ref<boolean>(false);
const applyLoading = ref<boolean>(false);
const applyCurrentLoading = ref<boolean>(false);
const isFailed = ref<boolean>(false);
const isWorkingTab = ref<boolean>(false);
const isApplyDataForCopiedWidgets = ref(false);

const hideDrawer = (): void => {
  widgetSettingsStore.updateTagDrawerValue(false);
};

const currentWidgetTagValue = computed<string>(() => {
  return widgetSettingsStore.selectedWidget?.options?._tag || "";
});

const updatePages = async (): Promise<Page[]> => {
  const pagesList = await fetchAllPages();

  return pagesList;
};

const onSelectChange = (changableRowKeys: string[]): void => {
  selected.value = changableRowKeys;
};

const isRowDisabled = (record: any): boolean => {
  if (
    record.name !== widgetSettingsStore.selectedWidget?.name ||
    record.id === widgetSettingsStore.selectedWidget?.id
  ) {
    return true;
  }

  return false;
};

const rowSelection = computed<any>(() => {
  return {
    selectedRowKeys: unref(selected),
    onChange: onSelectChange,
    hideDefaultSelections: true,
    getCheckboxProps: (record: any) => ({
      disabled: isRowDisabled(record), // Column configuration not to be checked
      name: record.name,
    }),
  };
});

const ignoredPagesWithSimilarWidgets = computed<string[]>(() => {
  return Object.keys(pagesWithErrors.value).filter(pageId => {
    return similarWidgetsToSelected.value.find(
      widgetMeta => widgetMeta.page.pageId === pageId
    );
  });
});

/*
  Show disabled state if different widget name with same tag
*/
const currentRowClassname = (record: any): string => {
  if (record.name === widgetSettingsStore.selectedWidget?.name) {
    return "";
  }

  return "widget-tag-drawer__row--disabled";
};

/*
  Get only selected container widgets children
*/
const getSelectedWidgetsChildrenData = (
  selectedList: string[]
): ChildrenWidgets => {
  return selectedList.reduce((result, widgetData) => {
    const widgetId = widgetData.split("==")[2];

    if (!similarWidgetsChildren.value[widgetId]) {
      return result;
    }

    (result as ChildrenWidgets)[widgetId] =
      similarWidgetsChildren.value[widgetId];

    return result;
  }, {});
};

const applyChangesToSimilar = async (): Promise<void> => {
  applyLoading.value = true;
  const selectedWidgetsChildren = getSelectedWidgetsChildrenData(
    selected.value
  );

  try {
    await applyWidgetChangesToList(
      widgetSettingsStore.selectedWidget!,
      Object.values(selected.value),
      {
        isApplyDataForCopiedWidgets: isApplyDataForCopiedWidgets.value,
      },
      selectedWidgetsChildren
    );

    metaStore.setGlobalProcessState(false);

    applyLoading.value = false;
    metaStore.updateGlobalStatus(false);
    handleActionSuccess(SUCCESSFULLY_UPDATED);
  } catch (err) {
    handleActionError("Something went wrong");
    console.error(err);
    applyLoading.value = false;
  }
};

const applyToCurrentWidget = async () => {
  hideDrawer();
};

watch(
  () => drawerValue.value,
  async value => {
    if (!value) {
      pagesContentData.value = {};
      pagesData.value = {};
      templatesData.value = {};
      similarWidgetsToSelected.value = [];
      selected.value = [];
      pagesList.value = [];
      isFailed.value = false;

      if (isWorkingTab.value) {
        metaStore.stopWorkingStatusInterval();
        metaStore.updateGlobalStatus(false);
      }

      return;
    }

    if (!currentWidgetTagValue.value) {
      return;
    }

    const globalStatus = await metaStore.fetchGlobalStatus();

    metaStore.setGlobalProcessState(globalStatus);

    if (metaStore.globalProcessIsRunning) {
      return false;
    }

    try {
      loading.value = true;
      metaStore.setGlobalProcessState(true);
      isWorkingTab.value = true;
      metaStore.updateGlobalStatus(true);
      metaStore.runUpdateWorkingStatusInterval();

      await updatePages();
      await fetchPagesContent();
      await fetchTemplatesData();

      /* 
        Find similar widgets(and it's children in ALL pages)
      */
      const similarData = findSimilarTagWidgetsInGrid(
        pagesContentData.value,
        widgetSettingsStore.selectedWidget
      );

      /*
        All similar widgets children from ALL pages
        in format {parentWidgetId: child[]}
      */
      similarWidgetsChildren.value = similarData.children;

      /* List of similar widgets */
      similarWidgetsToSelected.value = similarData.similar;

      /*
        Selected checkboxes as string[] with "key" values
      */
      selected.value = similarWidgetsToSelected.value
        .filter(
          widget => widget.name === widgetSettingsStore.selectedWidget?.name
        )
        .map(w => w.key as string);

      loading.value = false;
    } catch (e) {
      isFailed.value = true;
      loading.value = false;
      handleActionError("Something went wrong");
    }
  },
  {
    immediate: true,
  }
);

onBeforeUnmount(() => {
  metaStore.stopWorkingStatusInterval();
});

const errorText = computed<string>(() => {
  if (applyLoading.value || loading.value) {
    return "";
  }

  if (!currentWidgetTagValue.value) {
    return "Tag value is not provided";
  }

  if (
    metaStore.globalProcessIsRunning &&
    !similarWidgetsToSelected.value.length &&
    !isWorkingTab.value
  ) {
    return "There is already active global process in project. Please try again later";
  }

  if (isWorkingTab.value && !similarWidgetsToSelected.value.length) {
    return "No similar widgets found";
  }

  return "";
});
</script>
