import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 84;

const v84WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  widget.options.__VERSION = WIDGET_VERSION;

  const widgets = [
    "CardsListWidget",
    "PromoBetsFenixWidget",
    "ContainerWidget",
    "TopMatchFenixWidget",
    "CasinoGamesListFenixWidget",
  ];

  if (widgets.includes(widget.name)) {
    delete widget.content?.arrows?.options.states?.hover?.button?.cornerRadius;
    delete widget.content?.arrows?.options.states?.active?.button?.cornerRadius;
  }

  return widget;
};
export default v84WidgetMigration;
